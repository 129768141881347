<template>
    <div id="app">
        <app-header v-if="user"></app-header>
        <transition name="fade" mode="out-in">
            <router-view class="view"></router-view>
        </transition>
        <app-footer v-if="user"></app-footer>
    </div>
</template>

<script>
    import AppHeader from "../partials/AppHeader";
    import AppFooter from "../partials/AppFooter";

    export default {
        components: {
            AppHeader,
            AppFooter
        },
        computed: {
            user: function () {
                return this.$user
            }
        }
    }
</script>
