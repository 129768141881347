export default {
    en: {
        day: {
            weekday: 'long'
        },
        time: {
            hour: '2-digit',
            minute: '2-digit'
        },
        long: {
            day: 'numeric',
            weekday: 'long',
            hour: '2-digit',
            minute: '2-digit'
        }
    },
    nl: {
        day: {
            weekday: 'long'
        },
        time: {
            hour: '2-digit',
            minute: '2-digit'
        },
        long: {
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit'
        }
    },
    fr: {
        day: {
            weekday: 'long'
        },
        time: {
            hour: '2-digit',
            minute: '2-digit'
        },
        long: {
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            minute: '2-digit'
        }
    },
}
