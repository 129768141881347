<template>
    <div>
        <view-nav title="Movix mapping manager" @nav="w => this.week = w">
            <template v-if="childRoute === 'MovieMappingRemap'" v-slot:content="{ emit }">
                <weeks-nav @nav="emit"/>
            </template>
        </view-nav>

        <div class="view-content container-fluid">
            <div class="row">
                <div class="col-2">
                    <ul class="box nav">
                        <li class="nav-item w-100">
                            <router-link to="/movie-mapping" exact-path class="nav-link">
                                <icon name="link-2" class="red"></icon> Unknown movies
                            </router-link>
                        </li>
                        <li class="nav-item w-100">
                            <router-link to="/movie-mapping/remap" exact-path class="nav-link">
                                <icon name="link-2"></icon> Remap movies
                            </router-link>
                        </li>
                    </ul>
                    <div v-if="childRoute === 'MovieMappingRemap'" class="box">
                        <div class="box-content">
                            <heading v-for="(stat, label) in stats" key="label" :upper="label" border>
                                <template v-slot:lower><small>{{ stat }}</small></template>
                            </heading>
                        </div>
                    </div>
                    <div class="box">
                        <div class="box-content">
                            <heading v-if="childRoute === 'MovieMappingRemap'" upper="Help" border>
                                <template v-slot:lower><small>Replace an existing movie mapping by providing a new Movix ID.</small></template>
                            </heading>
                            <heading v-if="childRoute === 'MovieMappingUnknown'" upper="Help" border>
                                <template v-slot:lower><small>Once a source title is matched with a Movix movie it will disappear from this list.</small></template>
                            </heading>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <router-view :week="week" @stats="setStats"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Heading from "../partials/Heading";
    import ViewNav from "../partials/ViewNav";
    import WeeksNav from "../partials/WeeksNav";

    export default {
        components: {
            Heading,
            ViewNav,
            WeeksNav,
        },
        data() {
            return {
                week: null,
                childRoute: this.$route.name,
                stats: null,
            }
        },
        watch: {
            $route(to) {
                this.childRoute = to.name;
            },
        },
        methods: {
            setStats(stats) {
                this.stats = stats;
            }
        }
    }
</script>
