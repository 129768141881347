<template>
    <div class="view-nav">
        <div class="container-fluid p-0">
            <div class="row align-items-center">
                <div class="col-2">{{ title }}</div>
                <div class="col-10">
                    <slot name="content" :emit="(x, y, z) => $emit('nav', x, y, z)"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ViewNav',
    props: ['title'],
}
</script>
