<template>
    <div>
        <movix-create-form :show="showMatchingModal"
                           :input-title="mappingToMatch.source_title"
                           :input-release-date="defaultReleaseDate"
                           :context="mappingToMatch.context"
                           :altAsPublic="false"
                           @closed="showMatchingModal = false"
                           @created="onMovieCreated"/>

        <mapping-info-modal name="mapping-info"/>

        <div class="box view-nav-bar">
            <b-form-input v-model="searchTerm"
                          class="transparent-input"
                          type="search"
                          debounce="400"
                          placeholder="Search"
                          @change="currentPage = 1"/>

            <b-form-checkbox v-model="withSoftDeleted" switch>Show ignored</b-form-checkbox>

            <b-pagination v-if="totalRows !== null"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="data-table"/>
        </div>

        <div class="box">
            <div :class="'box-content pt-3' + (totalRows ? '' : ' d-none')">
                <b-table ref="dataTable"
                         id="data-table"
                         class="shx-table w-100"
                         hover dark fixed
                         sort-icon-left
                         :filter="searchTerm"
                         :fields="columns"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         :currentPage="currentPage"
                         :perPage="0"
                         :items="fetchData">

                    <template #cell(actions)="data">
                        <b-button size="sm" class="mr-1"
                                  v-if="!data.item.movix_id"
                                  @click="() => showInfo(data.item)">🛈</b-button>
                        <b-button size="sm" class="mr-1"
                                  @click="() => matchMovie(data.item)">Match</b-button>
                        <b-button size="sm" class="mr-1"
                                  v-if="!data.item.deleted_at"
                                  @click="() => ignoreMovie(data.item)">Ignore</b-button>
                    </template>

                </b-table>
            </div>

            <div v-if="!totalRows" class="box-content pt-4 text-center text-muted">No unknown movies.</div>
        </div>

        <div v-if="fetched" class="box">
            <b-pagination v-if="totalRows !== null"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="data-table"/>
        </div>
    </div>
</template>

<script>
    import {BButton, BFormCheckbox, BFormInput, BPagination, BTable} from "bootstrap-vue";
    import MappingInfoModal from "../partials/MappingInfoModal";
    import Heading from "../partials/Heading";
    import Modal from "../plugins/modal/Modal";
    import helpers from "../helpers/helpers";
    import axios from "axios";
    import dataTableLoading from "../mixins/data-table-loading";

    export default {
        components: {
            MappingInfoModal,
            BButton,
            BFormCheckbox,
            BFormInput,
            BPagination,
            BTable,
            Heading,
            Modal,
        },
        mixins: [
            dataTableLoading
        ],
        watch: {
            withSoftDeleted() {
                this.$refs.dataTable.refresh();
            }
        },
        data() {
            let params = helpers.getCurrentUrlQuery();

            this.perPage = this.$config.tableRows;

            this.defaultReleaseDate = this.$dayjs().format('YYYY-MM-DD');

            this.columns = [
                { key: 'source_title', label: 'Source title', sortable: true },
                { key: 'context', label: 'Context' },
                { key: 'created_at', label: 'Added at', sortable: true, formatter: helpers.tableDatetime },
                { key: 'actions', label: '', thClass: 'shx-th-200', tdClass: 'text-right' }
            ];

            return {
                fetched: true,
                mappingToMatch: {},
                showMatchingModal: false,
                withSoftDeleted: this.$helpers.castBooleanValue(params.ignored, false),
                searchTerm: params.term || '',
                currentPage: params.page || 1,
                totalRows: null, // do not set to `0` before data loaded, it otherwise it resets `currentPage` to `1`
                sortBy: params.sortBy || 'created_at',
                sortDesc: params.sort ? params.sort === 'desc' : true,
            }
        },
        methods: {
            fetchData(ctx, callback) {
                this.tableLoading(true);

                axios.get('/unknown-movies', {
                    params: {
                        filter: ctx.filter,
                        deleted: +this.withSoftDeleted,
                        orderBy: ctx.sortBy,
                        order: ctx.sortDesc ? 'desc' : 'asc',
                        page: ctx.currentPage,
                    }
                }).then(response => {
                    callback(response.data.data.unknown);
                    this.totalRows = response.data.data.total;
                }).then(() => {
                    this.tableLoading(false).replaceCurrentUri(ctx);
                }).catch(error => {
                    this.tableLoading(false).$toast.error(error.message);
                })
            },
            /**
             * Render the modal to present matching Movix titles.
             * @param {object} mapping
             * @return void
             */
            matchMovie(mapping) {
                const mappingToMatch = Object.assign({}, mapping);
                mappingToMatch.context = 'Showtimes: ' + mapping.context.toLowerCase();

                this.mappingToMatch = mappingToMatch;
                this.showMatchingModal = true;
            },
            /**
             * Avoid display entries which will never be mapped.
             * @param {object} mapping
             * @return void
             */
            ignoreMovie(mapping) {
                this.$store
                    .dispatch('ignoreMapping', {
                        id: mapping.id,
                    })
                    .then(() => {
                        this.$refs.dataTable.refresh();
                        this.$toast.success('Title ignored.');
                    })
                    .catch(() => {
                        this.$toast.error('This title could not be ignored.');
                    });
            },
            /**
             * Handle the response of the movie matching modal.
             * @param {object} createdMovie
             * @return void
             */
            onMovieCreated(createdMovie) {
                this.showMatchingModal = false;

                this.$store
                    .dispatch('storeMapping', {
                        id: this.mappingToMatch.id,
                        data: createdMovie
                    })
                    .then(() => {
                        this.$refs.dataTable.refresh();
                        this.$toast.success('Title mapped! Thank you.');
                    })
                    .catch(error => {
                        this.$toast.error(error.response.data.error.message || error.message);
                    });
            },
            showInfo(mapping) {
                this.$modal.prompt('mapping-info', {
                    mapping: mapping,
                });
            },
            replaceCurrentUri(ctx) {
                this.$router.replace({
                    name: 'MovieMappingUnknown',
                    query: {
                        term: ctx.filter,
                        ignored: +this.withSoftDeleted,
                        sortBy: ctx.sortBy,
                        sort: ctx.sortDesc ? 'desc' : 'asc',
                        page: ctx.currentPage,
                    }
                });
            },
        }
    }
</script>
