<template>
    <div id="admin-logs">
        <div class="box view-nav-bar">
            <multi-select v-model="filterLevels"
                          :options="$config.errorLevels"
                          placeholder="Level filter"
                          selectLabel=""
                          :taggable="true"
                          :multiple="true"
                          :searchable="false"/>

            <multi-select v-model="filterContexts"
                          :options="$config.serviceContexts"
                          placeholder="Context filter"
                          selectLabel=""
                          :taggable="true"
                          :multiple="true"
                          :searchable="false"/>

            <b-pagination v-if="totalRows !== null"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="data-table"/>
        </div>

        <div class="box">
            <div class="box-content pt-3">
                <b-table ref="dataTable"
                         id="data-table"
                         class="shx-table w-100"
                         hover dark fixed striped
                         sort-icon-left
                         :fields="columns"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         :currentPage="currentPage"
                         :perPage="0"
                         :items="fetchData">

                    <template #cell(created_at)="data">
                        <small>{{ $dayjs(data.item.created_at).format('DD/MM/YYYY HH:mm:ss.SSS') }}</small>
                    </template>

                    <template #cell(context)="data">
                        <small>{{ data.item.context }}</small>
                    </template>

                    <template #cell(subject)="data">
                        <div>
                            <span :class="'mr-2 badge badge-' + data.item.level">{{ data.item.level }}</span>
                            {{ data.item.subject || data.item.body }}
                        </div>
                    </template>

                    <template #cell(actions)="data">
                        <button v-if="data.item.gauges.length || (data.item.body && data.item.subject)"
                                @click="() => data.toggleDetails()" class="btn btn-sm btn-link">
                            <icon :name="'chevron-' + (data.detailsShowing ? 'up' : 'down')"/>
                        </button>
                    </template>

                    <template #row-details="data">
                        <p v-if="data.item.body" class="text-muted" v-html="$helpers.nl2br(data.item.body)"></p>
                        <ul v-if="data.item.gauges.length">
                            <li v-for="metric in data.item.gauges" :key="metric.id">
                                <span>{{ metric.key }}</span>:
                                <span>{{ metric.value + (metric.unit || '') }}</span>
                            </li>
                        </ul>
                    </template>
                </b-table>
            </div>
        </div>

        <div class="box">
            <b-pagination v-if="totalRows !== null"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="data-table"/>
        </div>
    </div>
</template>

<script>
    import {BPagination, BButton, BTable} from "bootstrap-vue";
    import axios from "axios";
    import helpers from "../helpers/helpers";
    import MultiSelect from "vue-multiselect";
    import dataTableLoading from "../mixins/data-table-loading";

    export default {
        components: {
            BButton,
            BPagination,
            BTable,
            MultiSelect,
        },
        mixins: [
            dataTableLoading
        ],
        data() {
            let params = helpers.getCurrentUrlQuery();

            this.perPage = this.$config.tableRows;

            this.columns = [
                { key: 'context', label: 'Context', thClass: 'shx-th-130' },
                { key: 'created_at', label: 'Date', sortable: true, thClass: 'shx-th-160' },
                { key: 'subject', label: 'Message' },
                { key: 'actions', label: '', thClass: 'shx-th-130', tdClass: 'text-right' }
            ];

            return {
                fetched: true,
                filterLevels: params.levels ? params.levels.split(',') : [],
                filterContexts: params.contexts ? params.contexts.split(',') : [],
                currentPage: params.page || 1,
                totalRows: null, // do not set to `0` before data loaded, it otherwise it resets `currentPage` to `1`
                sortBy: params.sortBy || 'created_at',
                sortDesc: params.sort ? params.sort === 'desc' : true,
            }
        },
        watch: {
            filterLevels() {
                this.currentPage = 1;
                this.$refs.dataTable.refresh();
            },
            filterContexts() {
                this.currentPage = 1;
                this.$refs.dataTable.refresh();
            },
        },
        methods: {
            fetchData(ctx, callback) {
                this.tableLoading(true);

                axios
                    .get(`/logs`, {
                        params: {
                            levels: this.filterLevels.join(','),
                            contexts: this.filterContexts.join(','),
                            order_by: ctx.sortBy,
                            order: ctx.sortDesc ? 'desc' : 'asc',
                            per_page: this.perPage,
                            page: ctx.currentPage,
                        }
                    })
                    .then(response => {
                        callback(response.data.data);
                        this.totalRows = response.data.total;
                    })
                    .then(() => {
                        this.tableLoading(false).replaceCurrentUri(ctx);
                    })
                    .catch(error => {
                        this.tableLoading(false).$toast.error(error.message);
                    })
            },
            replaceCurrentUri(ctx) {
                this.$router.replace({
                    name: 'Logs',
                    query: {
                        levels: this.filterLevels.join(','),
                        contexts: this.filterContexts.join(','),
                        sortBy: ctx.sortBy,
                        sort: ctx.sortDesc ? 'desc' : 'asc',
                        page: this.currentPage,
                    }
                })
            }
        }
    }
</script>
