var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "" + (_vm.data.count ? "" : "text-danger") }, [
    _c("div", { staticClass: "stats-title" }, [
      _c("span", { staticClass: "text-muted" }, [
        _vm._v(_vm._s(_vm.data.label_1))
      ]),
      _vm._v(" "),
      _c(
        "h4",
        { staticClass: "stats-title mb-0 d-flex justify-content-between" },
        [
          _c("span", [
            _vm.data.count
              ? _c("span", { staticClass: "text-success" }, [_vm._v("✔")])
              : _c("span", [_vm._v("⚡")]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(_vm.data.label_2) +
                  ": " +
                  _vm._s(_vm.data.count) +
                  " sessions"
              )
            ])
          ]),
          _vm._v(" "),
          _vm.data.count
            ? _c(
                "b-button",
                { attrs: { size: "sm" }, on: { click: _vm.fetchSessions } },
                [_vm._v("Show sessions")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "stats-bar mt-2",
      style: { width: ((_vm.data.count / _vm.max) * 100 || 0) + "%" }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "stats-sessions" }, [
      _vm.data.count && _vm.toggled
        ? _c(
            "div",
            {
              style: {
                height:
                  Math.max(75, Math.min(_vm.sessions.length * 30, 500)) + "px"
              }
            },
            [
              _c(
                "ol",
                _vm._l(_vm.sessions, function(session, i) {
                  return _c("li", { key: i, staticClass: "mb-2" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(session.session_day) +
                          " " +
                          _vm._s(session.session_time) +
                          " "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("[scr " + _vm._s(session.screen_number) + "]: ")
                    ]),
                    _vm._v(" "),
                    session.movix_id
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://movix.brightfish.be/movies/" +
                                session.movix_id,
                              target: "_blank",
                              rel: "noopener"
                            }
                          },
                          [_vm._v(_vm._s(session.source_title))]
                        )
                      : _c("span", [_vm._v(_vm._s(session.source_title))]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        " [" +
                          _vm._s(session.session_lang) +
                          "] (" +
                          _vm._s(session.session_type) +
                          ")"
                      )
                    ])
                  ])
                }),
                0
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }