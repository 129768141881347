<template>
    <div class="text-center">
        <spinner-bars class="mx-auto mt-4 mb-2"/>
        <span>Loading</span>
    </div>
</template>

<script>
    import SpinnerBars from "./SpinnerBars";

    export default {
        components: {
            SpinnerBars,
        }
    }
</script>
