<template>
    <div>
        <search-movix-modal name="movie-matcher" @confirmed="replaceMovixId"/>

        <mapping-info-modal name="mapping-info"/>

        <div class="box view-nav-bar">
            <b-form-select v-model="source"
                           :options="availableSources"
                           style="width: 210px"/>

            <b-form-input v-model="searchTerm"
                          class="transparent-input"
                          type="search"
                          debounce="400"
                          placeholder="Search by source title"
                          autofocus/>

            <b-pagination v-if="totalRows !== null"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="unknown-movies-table"/>
        </div>

        <div class="box">
            <div class="box-content pt-3">
                <div>
                    <b-table ref="dataTable"
                             id="data-table"
                             hover dark fixed
                             class="shx-table w-100"
                             sort-icon-left
                             :filter="searchTerm"
                             :fields="columns"
                             :sort-by.sync="sortBy"
                             :sort-desc.sync="sortDesc"
                             :currentPage="currentPage"
                             :perPage="0"
                             :items="fetchData">

                        <template #cell(session_datetime)="row">
                            <small v-if="row.item.ctx === 'session-import'"
                                   v-html="row.item.session_datetime.replace(/:00$/, '').replace(' ', '&nbsp;')"/>
                        </template>

                        <template #cell(movix_title)="row">
                            <a :href="`https://movix.brightfish.be/movies/${row.item.movix_id}/edit`"
                               target="_blank"
                               rel="noopener">{{ row.item.movix_title || row.item.movix_id }}</a>
                            <small v-if="row.item.movix_title" class="text-muted">{{ row.item.movix_id }}</small>
                            <span v-else class="text-warning">NO MOVIX META DATA</span>
                            <div v-if="row.item.similarity !== null" class="progress-bar-wrap">
                                <b-progress-bar :value="1"
                                                :max="1"
                                                :style="getTitleSimilarityClipPath(row.item)"
                                                default />
                            </div>
                        </template>

                        <template #cell(movix_release_date)="row">
                            <span v-if="row.item.movix_release_date" :class="isMappedOnOldMovie(row.item) ? 'text-warning' : ''">
                                {{ row.item.movix_release_date }}
                            </span>
                        </template>

                        <template #cell(movix_target_packs)="row">
                            <div>
                                <span v-for="tag in row.item.movix_target_packs"
                                      :key="tag"
                                      class="badge badge-info mr-1">{{ tag }}</span>
                            </div>
                        </template>

                        <template #cell(counts)="row">
                            <span>{{ $tc('plural.session', row.item.count) }} in {{ $tc('plural.theatre', row.item.theatre_count) }}</span>
                        </template>

                        <template #cell(actions)="data">
                            <b-button size="sm" class="mr-1"
                                      @click="() => showInfo(data.item)">🛈</b-button>
                            <b-button size="sm" v-on:click="() => matchMovie(data.item)">
                                Remap
                            </b-button>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>

        <div class="box">
            <b-pagination v-if="totalRows !== null"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="playlist-confirmations-table"/>
        </div>
    </div>
</template>

<script>
    import {
        BButton,
        BFormCheckbox,
        BFormInput,
        BFormSelect,
        BInputGroup,
        BInputGroupAppend,
        BPagination,
        BProgressBar,
        BTable,
    } from "bootstrap-vue";
    import {compareTwoStrings} from "string-similarity";
    import axios from "axios";
    import Heading from "../partials/Heading";
    import helpers from "../helpers/helpers";
    import SearchMovixModal from "../partials/SearchMovixModal";
    import MappingInfoModal from "../partials/MappingInfoModal";
    import dataTableLoading from "../mixins/data-table-loading";
    import Vue from "vue";

    export default {
        components: {
            BButton,
            BFormCheckbox,
            BFormInput,
            BFormSelect,
            BInputGroup,
            BInputGroupAppend,
            BPagination,
            BProgressBar,
            BTable,
            Heading,
            SearchMovixModal,
            MappingInfoModal,
        },
        mixins: [
            dataTableLoading
        ],
        props: ['week'],
        data() {
            const params = helpers.getCurrentUrlQuery();

            // These properties do not need to be reactive.
            this.columns = [
                { key: 'source_title', label: 'Source title', sortable: true },
                { key: 'session_datetime', label: 'First session', sortable: true },
                { key: 'movix_title', label: 'Movix title & ID', tdClass: 'shx-td-mvx-title' },
                { key: 'movix_release_date', label: 'Release date', sortable: true, thClass: 'shx-th-130' },
                { key: 'movix_target_packs', label: 'Target packs', thClass: 'shx-th-160' },
                { key: 'counts', label: 'theatres & sessions', thClass: 'shx-th-200' },
                { key: 'actions', label: '', thClass: 'shx-th-160', tdClass: 'text-right' },
            ];

            // Reactive props.
            return {
                fetching: false,
                source: params.source || 'sessions',
                availableSources: [
                    {value: 'sessions,admissions', text: 'Source: sessions & admissions'},
                    {value: 'sessions', text: 'Source: sessions'},
                    {value: 'admissions', text: 'Source: admissions'},
                ],
                searchTerm: params.term || '',
                currentPage: params.page || 1,
                perPage: this.$config.tableRows,
                totalRows: null, // do not set to `0` before data loaded, it otherwise it resets `currentPage` to `1`
                sortBy: params.sortBy || 'movix_release_date',
                sortDesc: params.sort ? params.sort === 'desc' : true,
            }
        },
        watch: {
            week(newValue, oldValue) {
                // Only initial load of the component the WeeksNav triggers a late week change event,
                // the condition avoids making two initial requests.
                if (oldValue) {
                    this.$refs.dataTable.refresh();
                }
            },
            source() {
                this.currentPage = 1;
                this.$refs.dataTable.refresh();
            },
        },
        methods: {
            fetchData(ctx, callback) {
                this.tableLoading(true);

                axios.get(`/movie-mapping/mapped`, {
                    params: {
                        source: this.source,
                        term: this.searchTerm,
                        weeks: this.week.code,
                        page: this.currentPage,
                        orderBy: ctx.sortBy,
                        order: ctx.sortDesc ? 'desc' : 'asc',
                    }
                }).then(response => {
                    const mappings = response.data.data.mappings.map(mapping => {
                        mapping.original_id = mapping.movix_id;
                        mapping.similarity = this.getTitleSimilarity(mapping);
                        return mapping;
                    });

                    this.calcStats(mappings);

                    callback(mappings);

                    this.totalRows = response.data.data.total;
                }).then(() => {
                    this.tableLoading(false).replaceCurrentUri(ctx);
                }).catch(error => {
                    this.tableLoading(false).$toast.error(error.message);
                })
            },
            showInfo(mapping) {
                this.$modal.prompt('mapping-info', {
                    mapping: mapping,
                    mvxRequired: true,
                });
            },
            /**
             * Render the modal to present matching Movix titles.
             * @param {object} mapping
             * @return void
             */
            matchMovie(mapping) {
                this.$modal.prompt('movie-matcher', {
                    term: mapping.source_title,
                    mapping: mapping
                });
            },
            /**
             * Handle the request to replace a source title -> movix id mapping
             * @param {object} movie
             * @param {object} options
             * @return void
             */
            replaceMovixId(movie, options) {
                Vue.prototype.$bar.start();

                axios.post(`/movie-mapping/replace`, {
                    title: options.mapping.source_title,
                    old_id: options.mapping.original_id,
                    new_id: movie.id,
                }).then(() => {
                    options.mapping.original_id = movie.id
                    options.mapping.changed = false;
                    this.$toast.success('Title remapped!');
                    this.$refs.dataTable.refresh();
                    Vue.prototype.$bar.finish();
                }).catch(error => {
                    this.$toast.error(error.response.data.error.message || error.message);
                    Vue.prototype.$bar.finish();
                });
            },
            replaceCurrentUri(ctx) {
                this.$router.replace({
                    name: 'MovieMappingRemap',
                    query: {
                        week: this.week.code,
                        source: this.source,
                        ...this.searchTerm && {term: this.searchTerm},
                        sortBy: ctx.sortBy,
                        sort: ctx.sortDesc ? 'desc' : 'asc',
                        page: this.currentPage,
                    }
                })
            },
            isMappedOnOldMovie(movie) {
                const session_date = movie.session_datetime.split(' ').shift(),
                    releaseYearDiff = this.$dayjs(session_date).diff(
                        this.$dayjs(movie.movix_release_date), 'years'
                    );

                return releaseYearDiff > this.$config.movieMapping.yearDiffThreshold
            },
            /**
             * Calculate the similarity between the source title of a session/admission and its matched Movix title.
             * @return number|null
             */
            getTitleSimilarity(movie) {
                if (movie.movix_title === null) {
                    return null;
                }
                return compareTwoStrings(
                    movie.source_title.toLowerCase(),
                    movie.movix_title.toLowerCase()
                );
            },
            /**
             * CSS string for text similarity gradient.
             * @return string
             */
            getTitleSimilarityClipPath(movie) {
                const right = 100 - (movie.similarity * 100);

                return `clip-path: inset(0 ${right}% 0 0); padding-right: ${right}%;`
            },
            /**
             * Calculate and emit some stats about the fetched mappings.
             * @param {array} mappings
             * @return void
             */
            calcStats(mappings) {
                const stats = {
                    'Matches with 80%+ similarity': mappings.filter(m => m.similarity !== null && m.similarity >= .8).length.toString()
                        + ' of ' + mappings.length.toString() + ' displayed',
                };

                this.$emit('stats', stats);
            },
        }
    }
</script>
