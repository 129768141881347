import axios from "axios";
import sha256 from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";

/**
 * Basic definition of User class with properties and methods needed by Stargate.
 * @typedef {Object} User
 * @property {array} abilities
 * @property {function} onSgLogin
 * @property {function} onSgLogout
 */

/**
 * Add headers to Axios requests.
 * @param {string|object} key
 * @param {*} value
 * @return void
 */
export function setHttpHeader(key, value) {
    const values = typeof key === 'object' && key !== null ? key : {[key]: value};

    Object.keys(values).forEach(key => axios.defaults.headers.common[key] = values[key])
}

/**
 * Remove an Axios header.
 * @param {string} key
 * @return void
 */
export function unsetHttpHeader(key) {
    delete axios.defaults.headers.common['Authorization'];
}

/**
 * Create a random string.
 * @param {number} length
 * @return {string}
 */
export function randomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
        charactersLength = characters.length;

    let result = '';

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

/**
 * Safely encode a given string to base64 for URLs.
 * @param {string} str
 * @return {string}
 */
export const base64URL = str => {
    return str.toString(base64)
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
};

/**
 * Generate an OAuth 2 PKCE code challenge.
 * @param {string} code_verifier
 * @return {string}
 */
export const generateCodeChallenge = code_verifier => base64URL(sha256(code_verifier));


/**
 * Return the payload data of an access token.
 * @param {string} token
 * @return {any}
 */
export const decodeJwtPayload = token => {
    let base64Url = token.split('.')[1],
        base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'),
        jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

    return JSON.parse(jsonPayload);
};

/**
 * Trim given chars off a string.
 * @param {string} str
 * @param {string} chars
 * @return {string}
 */
export const rTrimString = (str, chars = '\\s') => {
    const regex = new RegExp(`[${chars}]+$`, 'g');
    return str.replace(regex, '');
};
