var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "sg-cb", style: _vm.cssVars }, [
      !_vm.guard
        ? _c("div", [
            _vm._m(0),
            _vm._v(" "),
            _c("p", { staticClass: "mt-2" }, [_vm._v("Logging you in...")])
          ])
        : _c(
            "div",
            [
              _c("ascii-fish"),
              _vm._v(" "),
              _c("div", { staticClass: "sg-error-msg" }, [
                _c("p", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.guard))]),
                _vm._v(" "),
                _vm._m(1)
              ])
            ],
            1
          )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sg-spin" }, [
      _c("div", { staticClass: "sg-spin-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "sg-spin-2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-2" }, [
      _vm._v("Please "),
      _c("a", { attrs: { href: "/login" } }, [_vm._v("login")]),
      _vm._v(" again.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }