var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("view-nav", {
        attrs: { title: "Dashboard" },
        on: {
          nav: function(w) {
            return (this$1.week = w)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var emit = ref.emit
              return [_c("weeks-nav", { on: { nav: emit } })]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "view-content container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2" }, [
            _vm.stats.length
              ? _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c("b-form-select", {
                      staticClass: "w-100",
                      attrs: { options: _vm.dashboardTypes },
                      on: { change: _vm.onDashboardChange },
                      model: {
                        value: _vm.dashboardType,
                        callback: function($$v) {
                          _vm.dashboardType = $$v
                        },
                        expression: "dashboardType"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.stats.length
              ? _c("div", { staticClass: "box" }, [
                  _c(
                    "div",
                    { staticClass: "box-content" },
                    [
                      _c("heading", {
                        attrs: {
                          upper: "Total " + _vm.statsMeta.type,
                          lower: _vm.statsMeta.total,
                          border: ""
                        }
                      }),
                      _vm._v(" "),
                      _vm.statsMeta.type === "sessions"
                        ? _c("heading", {
                            attrs: {
                              upper: "Theatres ready",
                              lower:
                                _vm.localStats.ready +
                                " (" +
                                _vm.localStats.readyPercent +
                                "%)",
                              border: ""
                            }
                          })
                        : _c("heading", {
                            attrs: {
                              upper: "Total",
                              lower: "" + _vm.localStats.totalItems,
                              border: ""
                            }
                          })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.statsMeta.imported_at
              ? _c("div", { staticClass: "box" }, [
                  _c(
                    "div",
                    { staticClass: "box-content" },
                    [
                      _c("heading", {
                        attrs: {
                          upper: "Last import",
                          lower: _vm.formatDate(_vm.statsMeta.imported_at),
                          border: ""
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-10" }, [
            _c("div", { staticClass: "box" }, [
              _vm.stats.length
                ? _c("div", { staticClass: "box-content" }, [
                    _c(
                      "ul",
                      { staticClass: "stats-list" },
                      _vm._l(_vm.stats, function(stat, i) {
                        return _c(
                          "li",
                          { key: i, staticClass: "mb-3 border-bottom" },
                          [
                            _vm.dashboardType === "sessions"
                              ? _c("dashboard-session-stats-item", {
                                  attrs: {
                                    data: stat,
                                    max: _vm.statsMeta.max,
                                    week: _vm.week.code
                                  }
                                })
                              : _c("dashboard-stats-item", {
                                  attrs: { data: stat, max: _vm.statsMeta.max }
                                })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ])
                : _c("div", { staticClass: "box-content" }, [
                    _c("p", [_vm._v("No data for this filters.")])
                  ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }