var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box view-nav-bar" },
      [
        _c(
          "b-form-checkbox",
          {
            attrs: { switch: "" },
            model: {
              value: _vm.fromApiOnly,
              callback: function($$v) {
                _vm.fromApiOnly = $$v
              },
              expression: "fromApiOnly"
            }
          },
          [_vm._v("API")]
        ),
        _vm._v(" "),
        _c("b-form-input", {
          staticClass: "transparent-input",
          attrs: {
            type: "search",
            debounce: "400",
            placeholder: "Search by source title"
          },
          model: {
            value: _vm.searchTerm,
            callback: function($$v) {
              _vm.searchTerm = $$v
            },
            expression: "searchTerm"
          }
        }),
        _vm._v(" "),
        _c("multi-select", {
          attrs: {
            options: _vm.theatres,
            placeholder: "Theatre",
            selectLabel: "",
            "track-by": "code",
            label: "name",
            searchable: true
          },
          model: {
            value: _vm.theatre,
            callback: function($$v) {
              _vm.theatre = $$v
            },
            expression: "theatre"
          }
        }),
        _vm._v(" "),
        _vm.totalRows !== null
          ? _c("b-pagination", {
              attrs: {
                "total-rows": _vm.totalRows,
                "per-page": _vm.perPage,
                "aria-controls": "data-table"
              },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "box" }, [
      _c(
        "div",
        { class: "box-content pt-3" + (_vm.totalRows ? "" : " d-none") },
        [
          _c("b-table", {
            ref: "dataTable",
            staticClass: "shx-table w-100",
            attrs: {
              id: "data-table",
              hover: "",
              dark: "",
              fixed: "",
              "sort-icon-left": "",
              busy: _vm.fetching,
              filter: _vm.searchTerm,
              fields: _vm.columns,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              currentPage: _vm.currentPage,
              perPage: 0,
              items: _vm.fetchData
            },
            on: {
              "update:sortBy": function($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function($event) {
                _vm.sortDesc = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "cell(theatre_code)",
                fn: function(data) {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-sm",
                        on: { click: _vm.handleTheatreTableClick }
                      },
                      [_vm._v(_vm._s(data.item.theatre_code))]
                    )
                  ]
                }
              },
              {
                key: "cell(source)",
                fn: function(data) {
                  return [
                    !data.item.source_url
                      ? _c("span", [_vm._v(_vm._s(data.item.source))])
                      : _c(
                          "a",
                          {
                            attrs: {
                              href: data.item.source_url,
                              target: "_blank",
                              rel: "noopener"
                            }
                          },
                          [_vm._v(_vm._s(data.item.source))]
                        )
                  ]
                }
              },
              {
                key: "cell(sessions_count)",
                fn: function(data) {
                  return [
                    _c("span", { staticClass: "badge badge-info" }, [
                      _vm._v(_vm._s(data.item.sessions_count))
                    ])
                  ]
                }
              },
              {
                key: "cell(actions)",
                fn: function(data) {
                  return [
                    data.item.sessions.length
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-link",
                            on: { click: data.toggleDetails }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                name:
                                  "chevron-" +
                                  (data.detailsShowing ? "up" : "down")
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "row-details",
                fn: function(data) {
                  return [
                    _c("sessions-table", {
                      attrs: { sessions: data.item.sessions }
                    })
                  ]
                }
              },
              {
                key: "table-busy",
                fn: function() {
                  return [_c("table-spinner")]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.totalRows
        ? _c(
            "div",
            { staticClass: "box-content pt-4 text-center text-muted" },
            [_vm._v("No lineups yet.")]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box" },
      [
        _vm.totalRows !== null
          ? _c("b-pagination", {
              attrs: {
                "total-rows": _vm.totalRows,
                "per-page": _vm.perPage,
                "aria-controls": "data-table"
              },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }