import axios from "axios";

const
    types = {
        SETTINGS: 'settings',
    },
    state = {
        [types.SETTINGS]: {},
    },
    mutations = {
        [types.SETTINGS](state, data) {
            state[types.SETTINGS] = data;
        }
    },
    getters = {
        settings: state => state[types.SETTINGS],
    },
    actions = {
        fetchSettings({commit}, bypassCache = true) {
            if (!bypassCache && state[types.SETTINGS]) {
                return Promise.resolve(state[types.SETTINGS]);
            }

            return axios
                .get(`/settings`)
                .then(response => {
                    commit(types.SETTINGS, response.data.data);
                    return response.data.data;
                })
        }
    };

export default {
    state,
    mutations,
    getters,
    actions
}
