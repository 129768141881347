<template>
    <nav class="container-fluid">
        <div class="row align-items-center">
            <div class="dashboard-nav-weeks-col col-11 p-0">
                <div class="overflow-hidden position-relative">
                    <ul class="nav" :style="'width:' + (86 * weeks.length) + 'px'">
                        <li v-for="week in weeks" :key="week.code" class="nav-item">
                            <a href="#"
                               @click.prevent="() => handleClick(week)"
                               :class="['nav-link', `w${week.code}`, week.code === active.code ? 'active' : '']">
                                <span>
                                    Week {{ week.number }}
                                    <span v-if="week.code === current.code" class="badge badge-pill badge-primary">Current</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-1 pr-0 text-right">
                <button class="btn btn-sm btn-primary" @click="setPreviousWeek"><</button>
                <button class="btn btn-sm btn-primary" @click="setNextWeek">></button>
            </div>
        </div>
    </nav>
</template>

<script>
    import helpers from "../helpers/helpers";
    import decorateSlice from "../models/decorateSlice";

    export default {
        data() {
            let storeGetters = this.$store.getters,
                collection = storeGetters.slices,
                current = storeGetters.current,
                active = this.setInitialWeek(collection, current);

            this.weeks = collection;
            this.weeksTotal = collection.length;

            return {
                current: current,
                active: active,
                activeIdx: collection.findIndex(slice => slice.code === active.code),
            }
        },
        mounted() {
            // Cache nav element
            this.navParentEl = this.$el.getElementsByClassName('nav')[0].parentNode;

            // Position the nav on the initial week.
            window.setTimeout(() => {
                this.setScrollPosition(this.active.code);
            }, 75)
        },
        methods: {
            setInitialWeek(slices, current) {
                const query = helpers.getCurrentUrlQuery(),
                    week = query.week ? this.$store.getters.byCode(query.week) : null,
                    init = week || ((new Date()).getDay() < 2 ? this.$store.getters.nextSlice(current) : current);

                this.$emit('nav', init);

                return init;
            },
            handleClick(week) {
                this.active = week;
                this.activeIdx = this.weeks.findIndex(slice => slice.code === week.code);
            },
            setPreviousWeek() {
                if (this.activeIdx > 0) {
                    this.active = this.$store.getters.byIndex(--this.activeIdx);
                }
            },
            setNextWeek() {
                if (this.activeIdx < this.weeksTotal - 1) {
                    this.active = this.$store.getters.byIndex(++this.activeIdx);
                }
            },
            setScrollPosition(weekCode) {
                let position = this.navParentEl.getElementsByClassName(`w${weekCode}`)[0].offsetLeft;

                window.requestAnimationFrame(() => {
                    this.navParentEl.scroll({
                        top: 0,
                        left: Math.max(0, position - (this.navParentEl.clientWidth / 2)),
                        behavior: 'smooth'
                    });
                });
            }
        },
        watch: {
            active(week) {
                this.setScrollPosition(week.code);
                this.$emit('nav', decorateSlice(this.active));
            }
        }
    }
</script>
