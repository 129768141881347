var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade", duration: { enter: 1.75, leave: 0 } } },
    [
      _vm.visible
        ? _c(
            "div",
            { attrs: { id: "modal-wrap" }, on: { click: _vm.onClick } },
            [
              _c(
                "transition",
                { attrs: { name: "fade", duration: { enter: 1, leave: 0 } } },
                [
                  _c(
                    "div",
                    {
                      class: "modal-" + _vm.type + " modal-" + _vm.name,
                      attrs: { id: "modal" }
                    },
                    [
                      _c("div", { staticClass: "modal-body" }, [
                        _c(
                          "div",
                          { staticClass: "modal-title font-weight-bold" },
                          [_vm._t("title")],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal-text" },
                          [_vm._t("text")],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "modal-footer" },
                        [
                          _vm._t("footer", function() {
                            return [
                              _vm.type === "prompt"
                                ? _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-box modal-cancel",
                                        attrs: { type: "button" }
                                      },
                                      [_vm._v(_vm._s(_vm.btnCancel))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-box modal-confirm",
                                        attrs: { type: "button" }
                                      },
                                      [_vm._v(_vm._s(_vm.btnConfirm))]
                                    )
                                  ])
                                : _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-box modal-confirm",
                                        attrs: { type: "button" }
                                      },
                                      [_vm._v(_vm._s(_vm.btnConfirm))]
                                    )
                                  ])
                            ]
                          })
                        ],
                        2
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }