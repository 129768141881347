export default {
    en: {
        main: {
            Theatre: 'Theatre',
            Group: 'Group',
            CinemaWeek: 'Cinema week',
            Week: 'Week',
            current: 'current',
            movie: 'no movies | {n} movie | {n} movies',
            OV: 'Original version',
            FR: 'French-speaking version',
            NL: 'Dutch-speaking version',
            NoneMatching: 'None of these are matching',
            Cancel: 'Cancel',
            Confirm: 'Confirm',
        },
        plural: {
            movie: 'no movies | 1 movie | {n} movies',
            titles: 'no titles | 1 title | {n} titles',
            session: 'no sessions | 1 session | {n} sessions',
            theatre: 'no theatres | 1 theatre | {n} theatres',
        },
        menu: {
            Profile: 'My profile',
            Logout: 'Log out',
        },
        profile: {
            YourProfile: 'Your Profile',
        }
    },
    nl: {
        main: {
            Theatre: 'Bioscoop',
            Group: 'Groep',
            CinemaWeek: 'Cinema week',
            Week: 'Week',
            current: 'huidige',
            movie: 'geen films | {n} film | {n} films',
            OV: 'Originele versie',
            FR: 'Franstalige versie',
            NL: 'Nederlandstalige versie',
            NoneMatching: 'Geen van bovenstaande',
            Cancel: 'Annuleer',
            Confirm: 'Bevestig',
        },
        plural: {
            movie: 'geen films | 1 film | {n} films',
            session: 'geen sessies | 1 sessie | {n} sessies',
            theatre: 'geen bioskopen | 1 bioskoop | {n} bioskopen',
        },
        menu: {
            Profile: 'Mijn profiel',
            Logout: 'Afmelden',
        },
        profile: {
            YourProfile: 'Jouw profiel',
        }
    },
    fr: {
        main: {
            Theatre: 'Cinéma',
            Group: 'Groupe',
            CinemaWeek: 'Semaine cinéma',
            Week: 'Semaine',
            current: 'actuelle',
            movie: 'aucun films | {n} film | {n} films',
            OV: 'Version originale',
            FR: 'Version francophone',
            NL: 'Version néerlandophone',
            NoneMatching: 'Aucun titre ne correspond',
            Cancel: 'Annuler',
            Confirm: 'Confirmer',
        },
        plural: {
            movie: 'pas de films | 1 film | {n} films',
        },
        menu: {
            Profile: 'Mon profil',
            Logout: 'Déconnexion',
        },
        profile: {
            YourProfile: 'Votre profil',
        }
    },
}
