import store from '../store/index';
import Ls from '@dotburo/ls-ttl';

const
    _DEFAULTS = {
        username: null,
        first: null,
        last: null,
        email: null,
        photo: null,
        roles: [],
        abilities: [],
        extra: {}
    },
    _STORAGE_KEY = 'email',
    storage = new Ls({
        encrypt: btoa.bind(window)
    })

export default class User {
    constructor(attributes = {}) {
        this._attributes = this._setAttributes(attributes);

        this._isLoggedIn = false;

        this._selectedTheatre = null;
    }

    /**
     * Attributes setter.
     * @param {object} attributes
     * @return {object}
     * @private
     */
    _setAttributes(attributes) {
        // Make sure all properties are present
        return Object.assign({}, _DEFAULTS, attributes);
    }

    get id() {
        return this._attributes.id;
    }

    get username() {
        return this._attributes.username;
    }

    get photo() {
        return this._attributes.photo;
    }

    get logged() {
        return this._isLoggedIn;
    }

    get email() {
        return this._attributes.email;
    }

    get roles() {
        return this._attributes.roles;
    }

    get abilities() {
        return this._attributes.abilities;
    }

    get theatres() {
        return this._attributes.extra.theaters;
    }

    set theatre(theatre) {
        this._selectedTheatre = theatre;
    }

    set lang(lang) {
        this._attributes.lang = lang;
        this.storeLocally();
        this.storeLocale(this._attributes[_STORAGE_KEY], lang);
    }

    can(ability) {
        return this._attributes.abilities.indexOf(ability) > -1;
    }

    is(role) {
        return this._attributes.roles.indexOf(role) > -1;
    }

    /**
     * If the user still exists in the local storage, we will retrieve them from there,
     * otherwise we use the given data to populate the user's attributes.
     * @param {object} data
     * @param {string} token
     * @param {number} expiresIn Seconds
     * @param {boolean} exists
     * @return void
     */
    onSgLogin(data, token, expiresIn, exists) {
        if (exists) {
            data = storage.get(data[_STORAGE_KEY])
        }

        data.lang = this.getLocale(data[_STORAGE_KEY]) || data.lang;

        this._attributes = data;

        this._isLoggedIn = true;

        // The App component watches locale changes, {@see app.js}
        store.dispatch('setLocale', this._attributes.lang);

        if (!exists) {
            this.storeLocally(data, expiresIn);
        }
    }

    /**
     * Things to do Stargate logs the user out.
     * @return void
     */
    onSgLogout() {
        this._isLoggedIn = false;
        this.deleteLocally()
    }

    /**
     * Set a custom locale for this user in localStorage.
     * @param {string} key
     * @param {string} lang
     * @return void
     */
    storeLocale(key, lang) {
        storage.set(key + '-locale', lang);
    }

    /**
     * Return the custom locale for this user from localStorage.
     * @param {string} key
     * @return {string|number}
     */
    getLocale(key) {
        return storage.get(key + '-locale');
    }

    /**
     * Store the user data in the browser.
     * @param {object} attributes
     * @param {Number} expiresIn
     * @return void
     */
    storeLocally(attributes = this._attributes, expiresIn = 0) {
        storage.set(attributes[_STORAGE_KEY], attributes, expiresIn);
    }

    /**
     * Remove the user data from the browser.
     * @return void
     */
    deleteLocally() {
        storage.delete(this.email);
    }

    /**
     * Return a new object from the attributes.
     * @return {object}
     */
    toObject() {
        return JSON.parse(this.stringify());
    }

    /**
     * Make a JSON string from the model.
     * @return {string}
     */
    stringify() {
        return JSON.stringify(this._attributes)
    }
}
