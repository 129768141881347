<template>
    <modal :name="name" type="simple" btn-confirm="OK" @opened="onOpen" @closed="onClose">
        <template v-slot:title><b>Recent {{ type }} for <span class="font-italic">{{ title }}</span></b></template>
        <template v-slot:text>
            <div v-if="loading">
                <spinner-bars />
            </div>
            <div v-else-if="records.length">
                <p>
                    These are the 5 most recent {{ type }} for <span class="font-italic text-nowrap">{{ title }}</span>.
                    The links provided for each record will help you finding more info about the movie.
                </p>
                <ol>
                    <li v-for="(data, i) in records" :key="i">
                        On {{ data.day + (data.time ?  ' at ' + data.time : '') }} in
                        <a v-if="data.url" :href="data.url" target="_blank" rel="noopener">
                            {{ data.theatre}}
                        </a>
                        <span v-else>{{ data.theatre }}</span>
                    </li>
                </ol>
            </div>
            <div v-else>
                <p>No data found for this title.</p>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from "../plugins/modal/Modal";
import SpinnerBars from "./SpinnerBars";

export default {
    components: {
        Modal,
        SpinnerBars,
    },
    props: ['name'],
    data() {
        return {
            loading: false,
            type: '',
            title: '',
            records: [],
        }
    },
    methods: {
        onOpen(options) {
            this.title = options.mapping.source_title;

            const movixIdsRequired = typeof options.mvxRequired !== 'undefined' ? options.mvxRequired : true;

            if (options.mapping.context === 'Admission import') {
                this.type = 'admissions';
                this.getAdmissions(options.mapping, movixIdsRequired);
            } else  {
                this.type = 'sessions';
                this.getSessions(options.mapping, movixIdsRequired);
            }
        },
        onClose() {
            this.loading = false;
            this.records = [];
        },
        /**
         * Fetch the sessions.
         * @param {object} mapping
         * @param {boolean|number} mvxRequired
         * @return {Promise<array>}
         */
        getSessions(mapping, mvxRequired) {
            this.loading = true;

            return this.$store.dispatch('fetchSessionsForTitle', {source_title: mapping.source_title, mvxRequired})
                .then(sessions => {
                    this.loading = false;
                    return this.records = sessions.map(session => {
                        return {
                            day: session.session_day,
                            time: session.session_time,
                            theatre: this.$store.getters.getTheatreByCode(session.lineup.theatre_code).name,
                            url: session.lineup.source_url
                        }
                    });
                })
                .catch(() => {
                    this.onClose()
                })
        },
        /**
         * Fetch the admissions.
         * @param {object} mapping
         * @param {boolean|number} mvxRequired
         * @return {Promise<void>}
         */
        getAdmissions(mapping, mvxRequired) {
            this.loading = true;

            return this.$store.dispatch('fetchAdmissionsForTitle', {source_title: mapping.source_title, mvxRequired})
                .then(admissions => {
                    this.loading = false;
                    return this.records = admissions.map(admission => {
                        return {
                            day: admission.day,
                            time: null,
                            theatre: this.$store.getters.getTheatreByCode(admission.theatre_code).name,
                            url: null
                        }
                    });
                })
                .catch(() => {
                    this.onClose()
                })
        },
    }
}
</script>
