<template>
    <div class="box-content">
        <heading border>
            <template v-slot:upper>
                <span>Recalculate weekly admissions</span>
                <spinner-bars v-if="running"/>
                <span v-else-if="done" style="color:green">✓</span>
            </template>
        </heading>
        <div class="d-flex">
            <b-input-group size="sm" prepend="for">
                <b-form-input v-model="weeks" placeholder="Weeks"/>
                <template #append>
                    <b-button size="sm" @click="runImport" variant="primary">Calculate</b-button>
                </template>
            </b-input-group>
        </div>
        <b-form-text>Insert a comma separated list of week codes.</b-form-text>
    </div>
</template>

<script>
import {BFormSelect, BButton, BInputGroup, BFormText, BFormInput} from "bootstrap-vue";
import Heading from "./Heading";
import SpinnerBars from "./SpinnerBars";
import axios from "axios";

export default {
    components: {
        Heading,
        BFormSelect,
        BButton,
        BInputGroup,
        BFormText,
        SpinnerBars,
        BFormInput,
    },
    data() {
        return {
            running: false,
            done: false,
            time: null,
            weeks: '',
        }
    },
    methods: {
        runImport() {
            if (!this.weeks.length) return;

            this.running = true;

            return axios
                .post(`/settings/run`, {
                    job: 'compile-weeks',
                    weeks: this.weeks,
                })
                .then(() => {
                    this.running = false;
                    this.done = true;
                })
                .catch(error => {
                    this.running = false;
                    this.$toast.error(error.message);
                })
        }
    }
}
</script>
