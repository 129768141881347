<template>
    <div>
        <view-nav title="Administration"/>

        <div class="view-content container-fluid">
            <div class="row">
                <div class="col-2">
                    <ul class="box nav">
                        <li class="nav-item w-100">
                            <router-link to="/admin" exact-path class="nav-link">
                                <icon name="settings" class="red"></icon> Settings
                            </router-link>
                        </li>
                        <li class="nav-item w-100">
                            <router-link to="/admin/logs" exact-path class="nav-link">
                                <icon name="settings"></icon> Logs
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-10">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ViewNav from "../partials/ViewNav";
export default {
    components: {
        ViewNav
    }
}
</script>
