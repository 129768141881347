var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "" + (_vm.data.count ? "" : "text-danger") }, [
    _c("div", { staticClass: "stats-title" }, [
      _c("span", { staticClass: "text-muted" }, [
        _vm._v(_vm._s(_vm.data.label_1))
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "stats-title mb-0" }, [
        _vm.data.count
          ? _c("span", { staticClass: "text-success" }, [_vm._v("✔")])
          : _c("span", [_vm._v("⚡")]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.data.label_2))])
      ])
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "stats-bar mt-2",
      style: { width: ((_vm.data.count / _vm.max) * 100 || 0) + "%" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }