import dayjs from "dayjs";

/**
 * Used by all getters which return a single slice.
 * Adds useful properties & methods on the fly.
 * @param {object} slice
 * @return {object|null}
 */
export default function (slice) {
    if (!slice) return null;

    // It has already been decorated.
    if (typeof slice.start !== 'string') return slice;

    slice.number = parseInt(slice.code.slice(-2), 10);
    slice.start = dayjs(slice.start);
    slice.end = dayjs(slice.end);

    return slice;
}
