var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("view-nav", { attrs: { title: "Administration" } }),
      _vm._v(" "),
      _c("div", { staticClass: "view-content container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2" }, [
            _c("ul", { staticClass: "box nav" }, [
              _c(
                "li",
                { staticClass: "nav-item w-100" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin", "exact-path": "" }
                    },
                    [
                      _c("icon", {
                        staticClass: "red",
                        attrs: { name: "settings" }
                      }),
                      _vm._v(" Settings\n                        ")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item w-100" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/admin/logs", "exact-path": "" }
                    },
                    [
                      _c("icon", { attrs: { name: "settings" } }),
                      _vm._v(" Logs\n                        ")
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-10" }, [_c("router-view")], 1)
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }