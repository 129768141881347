<template>
    <h4 :class="['h-label', (border ? '' : 'border-0')]">
        <span class="upper">
            <slot name="upper">{{ upper }}</slot>
        </span>
        <span class="lower">
            <slot name="lower">{{ lower }}</slot>
        </span>
    </h4>
</template>

<script>
    export default {
        props: {
            upper: String,
            lower: String|Number,
            border: Boolean
        },
    }
</script>
