<template>
    <div>
        <div class="view-nav">
            <div class="container-fluid p-0">
                <div class="row align-items-center">
                    <div class="col-2">{{ $t('profile.YourProfile') }}</div>
                    <div class="col-10"></div>
                </div>
            </div>
        </div>

        <div class="view-content container-fluid">
            <div class="row">
                <div class="col-2">
                    <div class="box">
                        &nbsp;
                    </div>
                </div>
                <div class="col-7">
                    <div class="box">
                        <div class="box-content"><locale-changer/></div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="box">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LocaleChanger from '../partials/LocaleChanger';

export default {
    components: {
        LocaleChanger
    },
    data() {
        return {}
    },
}
</script>
