import dayjs from "dayjs";

export default {
    /**
     * Return the current URL's parameters as an object.
     * @return {{}}
     */
    getCurrentUrlQuery() {
        let query= {},
            iterator = Array.from(new URL(location.href).searchParams.entries());

        iterator.forEach(entry => {
            let value = entry[1];

            if (value === 'true') value = true;
            if (value === 'false') value = false;

            query[entry[0]] = value;
        });

        return query;
    },

    /**
     * Cast the given value to a boolean.
     * @param {any} value
     * @param {Boolean} defaultValue
     * @return {boolean}
     */
    castBooleanValue(value, defaultValue) {
        return value !== undefined ? !!parseInt(value, 10) : !!defaultValue;
    },

    /**
     * Convert line breaks to HTML.
     * @param {string} str
     * @returns {string}
     */
    nl2br(str) {
        return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },

    /**
     * Format the given string to the default datetime format for tables.
     * @param {string} datetime
     * @return string
     */
    tableDatetime(datetime) {
        return dayjs(datetime).format('DD/MM/YYYY HH:mm:ss')
    }
}
