<template>
    <b-table ref="dataTable"
             id="data-table"
             dark fixed borderless small
             sort-icon-left
             class="shx-table w-100"
             :fields="columns"
             sort-by="session_day"
             :sort-desc="false"
             :currentPage="currentPage"
             :perPage="0"
             :items="sessions">

        <template #cell(session_day)="data">
            {{ data.item.session_day }} {{ data.item.session_time }}
            <span class="text-muted">[{{ data.item.screen_number }}]</span>
        </template>

        <template #cell(source_title)="data">
            <a v-if="data.item.movix_id" :href="`https://movix.brightfish.be/movies/${data.item.movix_id}`" target="_blank" rel="noopener">{{
                    data.item.source_title
            }}</a>
            <span v-else>{{ data.item.source_title }}</span>
            [{{ data.item.movix_id }}]
        </template>

        <template #cell(session_type)="data">
            <span class="text-muted">{{ data.item.session_type }}</span>, {{ data.item.session_lang }}
        </template>

    </b-table>
</template>

<script>
    import {BPagination, BTable} from "bootstrap-vue";

    export default {
        components: {
            BTable,
        },
        props: ['sessions'],
        data() {
            this.columns = [
                { key: 'session_day', label: 'Time & Screen', thClass: 'shx-th-200', sortable: true },
                { key: 'source_title', label: 'Source Title', thClass: '', sortable: true },
                { key: 'session_type', label: 'Type, Lang', thClass: 'shx-th-100' },
                { key: 'session_exhibitor_id', label: 'Exhibitor ID', thClass: 'shx-th-200' },
            ];

            return {
                currentPage: 1,
            };
        }
    }
</script>
