var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("movix-create-form", {
        attrs: {
          show: _vm.showMatchingModal,
          "input-title": _vm.mappingToMatch.source_title,
          "input-release-date": _vm.defaultReleaseDate,
          context: _vm.mappingToMatch.context,
          altAsPublic: false
        },
        on: {
          closed: function($event) {
            _vm.showMatchingModal = false
          },
          created: _vm.onMovieCreated
        }
      }),
      _vm._v(" "),
      _c("mapping-info-modal", { attrs: { name: "mapping-info" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box view-nav-bar" },
        [
          _c("b-form-input", {
            staticClass: "transparent-input",
            attrs: { type: "search", debounce: "400", placeholder: "Search" },
            on: {
              change: function($event) {
                _vm.currentPage = 1
              }
            },
            model: {
              value: _vm.searchTerm,
              callback: function($$v) {
                _vm.searchTerm = $$v
              },
              expression: "searchTerm"
            }
          }),
          _vm._v(" "),
          _c(
            "b-form-checkbox",
            {
              attrs: { switch: "" },
              model: {
                value: _vm.withSoftDeleted,
                callback: function($$v) {
                  _vm.withSoftDeleted = $$v
                },
                expression: "withSoftDeleted"
              }
            },
            [_vm._v("Show ignored")]
          ),
          _vm._v(" "),
          _vm.totalRows !== null
            ? _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  "aria-controls": "data-table"
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c(
          "div",
          { class: "box-content pt-3" + (_vm.totalRows ? "" : " d-none") },
          [
            _c("b-table", {
              ref: "dataTable",
              staticClass: "shx-table w-100",
              attrs: {
                id: "data-table",
                hover: "",
                dark: "",
                fixed: "",
                "sort-icon-left": "",
                filter: _vm.searchTerm,
                fields: _vm.columns,
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.sortDesc,
                currentPage: _vm.currentPage,
                perPage: 0,
                items: _vm.fetchData
              },
              on: {
                "update:sortBy": function($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": function($event) {
                  _vm.sortBy = $event
                },
                "update:sortDesc": function($event) {
                  _vm.sortDesc = $event
                },
                "update:sort-desc": function($event) {
                  _vm.sortDesc = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(actions)",
                  fn: function(data) {
                    return [
                      !data.item.movix_id
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-1",
                              attrs: { size: "sm" },
                              on: {
                                click: function() {
                                  return _vm.showInfo(data.item)
                                }
                              }
                            },
                            [_vm._v("🛈")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-1",
                          attrs: { size: "sm" },
                          on: {
                            click: function() {
                              return _vm.matchMovie(data.item)
                            }
                          }
                        },
                        [_vm._v("Match")]
                      ),
                      _vm._v(" "),
                      !data.item.deleted_at
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-1",
                              attrs: { size: "sm" },
                              on: {
                                click: function() {
                                  return _vm.ignoreMovie(data.item)
                                }
                              }
                            },
                            [_vm._v("Ignore")]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        !_vm.totalRows
          ? _c(
              "div",
              { staticClass: "box-content pt-4 text-center text-muted" },
              [_vm._v("No unknown movies.")]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.fetched
        ? _c(
            "div",
            { staticClass: "box" },
            [
              _vm.totalRows !== null
                ? _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      "aria-controls": "data-table"
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }