<template>
    <transition enter-active-class="fadeInUp" leave-active-class="fadeInDown">
        <div
            role="alert"
            :class="`toast toast-${type} ${show ? 'active' : ''}`"
            @mouseover="toggleTimer(true)"
            @mouseleave="toggleTimer(false)">
            <div class="toast-icon"></div>
            <div class="toast-text" v-html="message || '&nbsp;'"></div>
        </div>
    </transition>
</template>

<script>
import Timer from "./Timer";
import state from "./state";

export default {
    data() {
        return state
    },
    methods: {
        hide() {
            this.timer.stop();
            this.timer = null;
            this.show = false;
        },
        toggleTimer(newVal) {
            if (this.timer) {
                newVal ? this.timer.pause() : this.timer.resume();
            }
        }
    },
    watch: {
        show(newVal) {
            if (newVal) {
                this.timer = new Timer(this.hide, this.duration);
            }
        }
    },
}
</script>
