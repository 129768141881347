const DEFAULTS = {
    freeze: '#app',
    hideOnConfirm: true,
};

const modal = {
    install(Vue, options) {
        this.options = Object.assign({}, DEFAULTS, options);

        // Create a Vue instance that will be listening for and emitting events from/to our main instance.
        // This vm (aka an event bus) will be available as modal.vm.
        this.vm = new Vue()

        this.vm.$on('closed', () => modal._freezeUI(false))

        // Expose these methods, this is accessible in all components through this.$modal.*
        Vue.prototype.$modal = {
            show(name, options) {
                modal._initModal(name, options);
            },
            prompt(name, options = {}) {
                modal._initModal(name, options);
            }
        }
    },
    _initModal(name, options) {
        this._freezeUI();
        options = Object.assign({}, this.options, options);
        modal.vm.$emit('show', name, options);
    },
    _freezeUI(state = true) {
        document.querySelector(this.options.freeze).style.pointerEvents = !state ? 'none' : '';
    },
}

export default modal;
