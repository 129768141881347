import axios from "axios";
import decorateSlice from "../models/decorateSlice"
import dayjs from "dayjs";

const
    types = {
        CONDUCTOR_SLICES: 'cinema-weeks',
        CONDUCTOR_CURRENT: 'cinema-week-current'
    },
    storage = window.localStorage,
    storedSlices = storage.getItem(types.CONDUCTOR_SLICES),
    initialCollection = storedSlices ? JSON.parse(storedSlices): null,
    state = {
        slices: Object.freeze(initialCollection)
    },
    mutations = {
        [types.CONDUCTOR_SLICES](state, slices) {
            storage.setItem(types.CONDUCTOR_SLICES, JSON.stringify(slices));
            state.slices = slices
        },
    },
    getters = {
        /**
         * Return all slices.
         * @param {object} state
         * @return {array}
         */
        slices: state => state.slices,
        /**
         * Return the slice of the current date.
         * @param {object} state
         * @param {object} getters
         * @return {object}
         */
        current: (state, getters) => {
            const today = dayjs().format('YYYY-MM-DD'),
                current = getters.slices.find(slice => slice.start <= today && slice.end >= today);

            return decorateAndFreezeSlice(current);
        },
        /**
         * Return the slice for the given index.
         * @param {object} state
         * @param {object} getters
         * @return {function}
         */
        byIndex: (state, getters) => index => {
            return decorateAndFreezeSlice(getters.slices[index]);
        },
        /**
         * Return for the given code.
         * @param {object} state
         * @param {object} getters
         * @return {function}
         */
        byCode: (state, getters) => code => {
            const idx = findIndexByCode(getters.slices, code);

            return idx ? decorateAndFreezeSlice(getters.slices[idx]) : null;
        },
        /**
         * Return the slice next to the given/current one.
         * @param {object} state
         * @param {object} getters
         * @return {function}
         */
        nextSlice: (state, getters) => (slice = null, dir = 1) => {
            const ref = slice || getters.current,
                idx = Math.max(0, findIndexByCode(getters.slices, ref.code) + dir);

            return decorateAndFreezeSlice(getters.slices[idx]);
        },
        /**
         * Return the slice previous to the given/current one.
         * @param {object} state
         * @param {object} getters
         * @return {function}
         */
        previousSlice: (state, getters) => (slice = null) => getters.nextSlice(slice, -1)
    },
    actions = {
        /**
         * Get slices from Conductor through SHX.
         * @param {function} commit
         * @param {object} state
         * @return {Promise<array|null>}
         */
        fetchSlices({commit, state}) {
            return state.slices
                ? Promise.resolve(state.slices)
                : axios
                    .get(`/conductor/cinema-weeks`)
                    .then(response => {
                        if (response.data.data.length) {
                            const slices = reformatApiResponse(response.data.data);

                            commit(types.CONDUCTOR_SLICES, Object.freeze(slices));
                        }
                        return response.data.data
                    })
        }
    },
    /**
     * Block Vue from making slice reactive.
     * @param {object} slice
     * @return {object|null}
     */
    decorateAndFreezeSlice = slice => Object.freeze(decorateSlice(slice)),
    /**
     * Shortcut to filter the slice array by week code..
     * @param {array} slices
     * @param {string} code
     * @return array
     */
    findIndexByCode = (slices, code) => slices.findIndex(slice => slice.code === code),
    /**
     * Adapt some attributes (not) needed by SHX' front-end.
     * @param {array} slices
     * @return {array}
     */
    reformatApiResponse = slices => {
        return slices.map(slice => {
            delete slice.name;

            delete slice.length;

            slice.number = parseInt(slice.code.slice(-2), 10);

            return slice;
        })
    };


export default {
    state,
    mutations,
    getters,
    actions
}
