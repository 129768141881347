import axios from 'axios';
import Modal from './plugins/modal/index';
import Toast from './plugins/toast/index';
import Vue from 'vue';
import App from './views/App.vue';
import helpers from './helpers/helpers.js';
import vueI18n from './i18n';
import dayjs from "dayjs";
import store from './store';
import router from './router';
import {setHttpHeader} from "../../vendor/brightfish/stargate-client/resources/js/helpers";
import ProgressBar from "./partials/ProgressBar";
import config from './config';
import Stargate from "../../vendor/brightfish/stargate-client/resources/js/index";
import User from "./models/User";
import feather from 'vue-icon';
import MovixCreateForm from '@brightfish/movix-create-form';

//import {sync} from 'vuex-router-sync'

Vue.prototype.$http = axios;
Vue.prototype.$config = config;
Vue.prototype.$bar = new Vue(ProgressBar).$mount();
Vue.prototype.$helpers = helpers;
Vue.prototype.$dayjs = dayjs;

document.body.appendChild(Vue.prototype.$bar.$el);

setHttpHeader('X-Requested-With', 'XMLHttpRequest');
setHttpHeader('X-Brightfish-UI', '1');

axios.defaults.baseURL = config.apiBase;

//import titleMixin from './util/title'
//import * as filters from './util/filters'

// mixin for handling title
//Vue.mixin(titleMixin);

// register global utility filters.
//Object.keys(filters).forEach(key => {
//    Vue.filter(key, filters[key])
//});

// Initialise Stargate for authentication and the user model instance.
Vue.use(Stargate, {
    router: router,
    User: User,
    initialIntrospect: true,
    logLevel: 2,
});

// This should be retrieved after the user initialisation.
let locale = store.getters.locale || config.defaultLocale;

// Set localisation.
const i18n = vueI18n(locale);

// Instantiate other plugins.
Vue.use(Modal);
Vue.use(Toast);
Vue.use(feather);

// Movix modal form to match and create movies
Vue.use(MovixCreateForm, {
    context: 'Showtimes',
    axios: axios,
    apiPrefix: '/movix/',
    createMaxFutureYears: config.createMovieMaxFutureYears,
    allowHiding: true,
});

// sync the router with the vuex store.
// this registers `store.state.route`
//sync(store, router);

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    template: '<App/>',
    components: {
        App
    },
    computed: {
        /**
         * Allow the app component to watch Vuex state for `locale`.
         * @return {string}
         */
        locale() {
            return store.getters.locale;
        }
    },
    watch: {
        /**
         * Set the current user's lang and the global locale when the Vuex state changes.
         * @param {string} lang
         * @return void
         */
        locale: function(lang) {
            this.$user.lang = this.$root.$i18n.locale = document.documentElement.lang = lang;
        },
    },
});

// service worker
/*if ('https:' === location.protocol && navigator.serviceWorker) {
    navigator.serviceWorker.register('/service-worker.js')
}
*/
