import state from "./state";

const toast = {
    install(Vue) {
        // Expose the API, this is accessible in all components through this.$toast.*
        Vue.prototype.$toast = {
            success(msg) {
                show('success', msg);
            },
            error(msg) {
                show('error', msg);
            }
        }
    },
}

function show(type, msg) {
    state.type = type;
    state.message = msg;
    state.show = true;
}

export default toast;
