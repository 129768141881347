import Vue from 'vue';
import Vuex from 'vuex';
import main from './main';
import conductor from './conductor';
import movix from './movix';
import park from './park';
import mappings from './mappings';
import stats from "./stats";
import sessions from "./sessions";
import admissions from "./admissions";
import settings from "./settings";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        main,
        conductor,
        movix,
        park,
        mappings,
        stats,
        sessions,
        admissions,
        settings,
    }
})
