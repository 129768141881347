<template>
    <div :class="`${data.count ? '': 'text-danger'}`">
        <div class="stats-title">
            <span class="text-muted">{{ data.label_1 }}</span>
            <h4 class="stats-title mb-0 d-flex justify-content-between">
                <span>
                    <span v-if="data.count" class="text-success">✔</span>
                    <span v-else>⚡</span>
                    <span>{{ data.label_2 }}: {{ data.count }} sessions</span>
                </span>
                <b-button v-if="data.count" @click="fetchSessions" size="sm">Show sessions</b-button>
            </h4>
        </div>
        <div class="stats-bar mt-2" v-bind:style="{width: ((data.count / max) * 100 || 0) + '%'}"></div>
        <div class="stats-sessions">
            <div v-if="data.count && toggled" :style="{height: Math.max(75, Math.min(sessions.length * 30, 500)) + 'px'}">
                <ol>
                    <li v-for="(session, i) in sessions" :key="i" class="mb-2">
                        <span>{{ session.session_day }} {{ session.session_time }} </span>
                        <span>[scr {{ session.screen_number }}]: </span>
                        <a v-if="session.movix_id" :href="`https://movix.brightfish.be/movies/${session.movix_id}`" target="_blank" rel="noopener">{{
                                session.source_title
                        }}</a>
                        <span v-else>{{ session.source_title }}</span>
                        <span> [{{ session.session_lang }}] ({{ session.session_type }})</span>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
    import {BButton} from "bootstrap-vue";

    export default {
        components: {
            BButton,
        },
        props: ['type', 'data', 'max', 'week'],
        data() {
            return {
                loading: false,
                toggled: false,
                sessions: []
            }
        },
        methods: {
            fetchSessions() {
                if (this.toggled) {
                    return this.toggled = false;
                }

                this.loading = true;

                this.$store
                    .dispatch('fetchSessionsForTheatre', {
                        theatre: this.data.label_2,
                        week: this.week
                    })
                    .then(sessions => {
                        this.toggled = true;
                        this.loading = false;
                        this.sessions = sessions;
                    })
            }
        },
    }
</script>
