import Vue from 'vue';
import store from '../store';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard';
import Profile from '../views/Profile';
import Login from '../views/Login';
import MovieMapping from "../views/MovieMapping";
import MovieMappingUnknown from "../views/MovieMappingUnknown";
import MovieMappingRemap from "../views/MovieMappingRemap";
import Settings from "../views/Settings";
import Logs from "../views/Logs";
import Admin from "../views/Admin";
import PlaylistsBase from "../views/PlaylistsBase";
import PlaylistAvailablePings from "../views/PlaylistAvailablePings";
import PlaylistConfirmations from "../views/PlaylistConfirmations";
import Sessions from "../views/Sessions";
import SessionsByLineup from "../views/SessionsByLineup";
import Playlists from "../views/Playlists";

Vue.use(VueRouter);

const
    manageSettingsMeta = {
        stargateAuth: true,
        abilities: ['manage_settings']
    },
    router = new VueRouter({
        mode: 'history',
        fallback: false,
        //scrollBehavior: () => ({y: 0}),
        routes: [
            {
                path: '/',
                redirect: () => !Vue.prototype.$user ? '/login' : '/dashboard'
            },
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: manageSettingsMeta,
            },
            {
                path: '/sessions',
                component: Sessions,
                children: [
                    {
                        path: '',
                        name: 'SessionsByLineup',
                        component: SessionsByLineup,
                        alias: ['/sessions'],
                        meta: manageSettingsMeta,
                    },
                ],
            },
            {
                path: '/movie-mapping',
                component: MovieMapping,
                children: [
                    {
                        path: '',
                        name: 'MovieMappingUnknown',
                        component: MovieMappingUnknown,
                        alias: ['/movie-mapping'],
                        meta: manageSettingsMeta,
                    },
                    {
                        path: '/movie-mapping/remap',
                        name: 'MovieMappingRemap',
                        component: MovieMappingRemap,
                        meta: manageSettingsMeta,
                    },
                ],
            },
            {
                path: '/playlists',
                component: PlaylistsBase,
                children: [
                    {
                        path: '',
                        name: 'Playlists',
                        component: Playlists,
                        alias: ['/playlists'],
                        meta: manageSettingsMeta,
                    },
                    {
                        path: '/playlists/pings',
                        name: 'PlaylistAvailablePings',
                        component: PlaylistAvailablePings,
                        meta: manageSettingsMeta,
                    },
                    {
                        path: '/playlists/confirmations',
                        name: 'PlaylistConfirmations',
                        component: PlaylistConfirmations,
                        meta: manageSettingsMeta,
                    },
                ],
            },
            {
                path: '/admin',
                component: Admin,
                children: [
                    {
                        path: '',
                        name: 'Settings',
                        component: Settings,
                        alias: ['/admin'],
                        meta: manageSettingsMeta,
                    },
                    {
                        path: '/admin/logs',
                        name: 'Logs',
                        component: Logs,
                        meta: manageSettingsMeta,
                    },
                ],
            },
            {
                path: '/profile',
                name: 'Profile',
                component: Profile,
            },
            {
                path: '/login',
                name: 'Login',
                component: Login
            }
        ]
    });

// a global mixin that calls `asyncData` when a route component's params change
/*Vue.mixin({
    beforeRouteUpdate (to, from, next) {
        const { asyncData } = this.$options;
        if (asyncData) {
            asyncData({
                store: this.$store,
                route: to
            }).then(next).catch(next)
        } else {
            next()
        }
    }
});*/

router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const prevMatched = router.getMatchedComponents(from);
    let diffed = false;
    const activated = matched.filter((c, i) => {
        return diffed || (diffed = (prevMatched[i] !== c))
    });
    const asyncDataHooks = activated.map(c => c.asyncData).filter(_ => _);

    if (!asyncDataHooks.length) {
        return next()
    }

    Vue.prototype.$bar.start();
    Promise.all(asyncDataHooks.map(hook => hook({ store, route: to })))
        .then(() => {
            Vue.prototype.$bar.finish();
            next()
        })
        .catch(next)
});

export default router;
