<template>
    <div>
        <div class="box view-nav-bar">
            <b-form-checkbox v-model="fromApiOnly" switch>API</b-form-checkbox>

            <b-form-input v-model="searchTerm"
                          class="transparent-input"
                          type="search"
                          debounce="400"
                          placeholder="Search by source title"/>

            <multi-select v-model="theatre"
                          :options="theatres"
                          placeholder="Theatre"
                          selectLabel=""
                          track-by="code"
                          label="name"
                          :searchable="true"/>

            <b-pagination v-if="totalRows !== null"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="data-table"/>
        </div>

        <div class="box">
            <div :class="'box-content pt-3' + (totalRows ? '' : ' d-none')">
                <b-table ref="dataTable"
                         id="data-table"
                         class="shx-table w-100"
                         hover dark fixed
                         sort-icon-left
                         :busy="fetching"
                         :filter="searchTerm"
                         :fields="columns"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         :currentPage="currentPage"
                         :perPage="0"
                         :items="fetchData">

                    <template #cell(theatre_code)="data">
                        <b-button class="btn-sm" @click="handleTheatreTableClick">{{ data.item.theatre_code }}</b-button>
                    </template>

                    <template #cell(source)="data">
                        <span v-if="!data.item.source_url">{{ data.item.source }}</span>
                        <a v-else :href="data.item.source_url" target="_blank" rel="noopener">{{ data.item.source }}</a>
                    </template>

                    <template #cell(sessions_count)="data">
                        <span class="badge badge-info">{{ data.item.sessions_count }}</span>
                    </template>

                    <template #cell(actions)="data">
                        <button v-if="data.item.sessions.length"
                                @click="data.toggleDetails"
                                class="btn btn-link">
                            <icon :name="'chevron-' + (data.detailsShowing ? 'up' : 'down')"/>
                        </button>
                    </template>

                    <template #row-details="data">
                        <sessions-table :sessions="data.item.sessions"/>
                    </template>

                    <template #table-busy>
                        <table-spinner/>
                    </template>
                </b-table>
            </div>

            <div v-if="!totalRows" class="box-content pt-4 text-center text-muted">No lineups yet.</div>
        </div>

        <div class="box">
            <b-pagination v-if="totalRows !== null"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="data-table"/>
        </div>
    </div>
</template>

<script>
    import {BButton, BFormCheckbox, BFormInput, BPagination, BTable} from "bootstrap-vue";
    import axios from "axios";
    import helpers from "../helpers/helpers";
    import Modal from "../plugins/modal/Modal";
    import MultiSelect from "vue-multiselect";
    import TableSpinner from "../partials/TableSpinner";
    import SessionsTable from "../partials/SessionsTable";
    import dataTableLoading from "../mixins/data-table-loading";

    export default {
        components: {
            SessionsTable,
            TableSpinner,
            BButton,
            BFormCheckbox,
            BFormInput,
            BPagination,
            BTable,
            Modal,
            MultiSelect,
        },
        mixins: [
            dataTableLoading
        ],
        props: ['week'],
        asyncData({store}) {
            return Promise.all([
                store.dispatch('fetchTheaters'),
            ]);
        },
        data() {
            const params = helpers.getCurrentUrlQuery(),
                store = this.$store.getters;

            this.theatres = store.theatres;

            this.columns = [
                { key: 'week_code', label: 'Week', thClass: 'shx-th-80' },
                { key: 'theatre_code', label: 'Theatre', thClass: 'shx-th-80', tdClass: 'shx-td-p-0', sortable: true },
                { key: 'source', thClass: '', sortable: true },
                { key: 'sessions_count', label: 'Sessions', thClass: 'shx-th-100', tdClass: 'text-center', sortable: true},
                { key: 'updated_at', label: 'Updated At', sortable: true, formatter: helpers.tableDatetime },
                { key: 'actions', label: '', thClass: 'shx-th-60', tdClass: 'p-0 text-center align-middle' }
            ];

            return {
                fetching: false,
                theatre: params.theatre ? store.getTheatreByCode(params.theatre) : '',
                fromApiOnly: this.$helpers.castBooleanValue(params.api, false),
                searchTerm: params.term || '',
                currentPage: params.page || 1,
                perPage: this.$config.tableRows,
                totalRows: null, // do not set to `0` before data loaded, it otherwise it resets `currentPage` to `1`
                sortBy: params.sortBy || 'updated_at',
                sortDesc: params.sort ? params.sort === 'desc' : true,
            }
        },
        watch: {
            theatre() {
                this.currentPage = 1;
                this.$refs.dataTable.refresh();
            },
            week(newValue, oldValue) {
                // Only initial load of the component the WeeksNav triggers a late week change event,
                // the condition avoids making two initial requests.
                if (oldValue) {
                    this.$refs.dataTable.refresh();
                }
            },
            fromApiOnly(value, old) {
                this.currentPage = 1;
                this.$refs.dataTable.refresh();
            },
        },
        methods: {
            fetchData(ctx, callback) {
                this.tableLoading(true);

                axios
                    .get('/sessions', {
                        params: {
                            weeks: this.week.code,
                            term: this.searchTerm,
                            theatre: this.theatre ? this.theatre.code : null,
                            api: +this.fromApiOnly,
                            orderBy: ctx.sortBy,
                            order: ctx.sortDesc ? 'desc' : 'asc',
                            page: ctx.currentPage,
                        }
                    })
                    .then(response => {
                        callback(response.data.data.sessions);
                        this.totalRows = response.data.data.total;
                    })
                    .then(() => {
                        this.tableLoading(false).replaceCurrentUri(ctx);
                    })
                    .catch(error => {
                        this.tableLoading(false).$toast.error(error.message);
                    })
            },
            replaceCurrentUri(ctx) {
                this.$router.replace({
                    name: 'SessionsByLineup',
                    query: {
                        week: this.week.code,
                        ...this.theatre && {theatre: this.theatre.code},
                        ...this.searchTerm && {term: this.searchTerm},
                        api: +this.fromApiOnly,
                        sortBy: ctx.sortBy,
                        sort: ctx.sortDesc ? 'desc' : 'asc',
                        page: ctx.currentPage,
                    }
                });
            },
            handleTheatreTableClick(e) {
                e.preventDefault();

                this.theatre = this.$store.getters.getTheatreByCode(e.target.textContent);
            },
        }
    }
</script>
