var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("view-nav", {
        attrs: { title: "Movix mapping manager" },
        on: {
          nav: function(w) {
            return (this$1.week = w)
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.childRoute === "MovieMappingRemap"
              ? {
                  key: "content",
                  fn: function(ref) {
                    var emit = ref.emit
                    return [_c("weeks-nav", { on: { nav: emit } })]
                  }
                }
              : null
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "view-content container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2" }, [
            _c("ul", { staticClass: "box nav" }, [
              _c(
                "li",
                { staticClass: "nav-item w-100" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/movie-mapping", "exact-path": "" }
                    },
                    [
                      _c("icon", {
                        staticClass: "red",
                        attrs: { name: "link-2" }
                      }),
                      _vm._v(" Unknown movies\n                        ")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "nav-item w-100" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/movie-mapping/remap", "exact-path": "" }
                    },
                    [
                      _c("icon", { attrs: { name: "link-2" } }),
                      _vm._v(" Remap movies\n                        ")
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.childRoute === "MovieMappingRemap"
              ? _c("div", { staticClass: "box" }, [
                  _c(
                    "div",
                    { staticClass: "box-content" },
                    _vm._l(_vm.stats, function(stat, label) {
                      return _c("heading", {
                        key: "label",
                        attrs: { upper: label, border: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "lower",
                              fn: function() {
                                return [_c("small", [_vm._v(_vm._s(stat))])]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c(
                "div",
                { staticClass: "box-content" },
                [
                  _vm.childRoute === "MovieMappingRemap"
                    ? _c("heading", {
                        attrs: { upper: "Help", border: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "lower",
                              fn: function() {
                                return [
                                  _c("small", [
                                    _vm._v(
                                      "Replace an existing movie mapping by providing a new Movix ID."
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1342160548
                        )
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.childRoute === "MovieMappingUnknown"
                    ? _c("heading", {
                        attrs: { upper: "Help", border: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "lower",
                              fn: function() {
                                return [
                                  _c("small", [
                                    _vm._v(
                                      "Once a source title is matched with a Movix movie it will disappear from this list."
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          4149504461
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-10" },
            [
              _c("router-view", {
                attrs: { week: _vm.week },
                on: { stats: _vm.setStats }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }