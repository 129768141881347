<!--suppress CssUnresolvedCustomProperty -->
<template>
    <div>
        <div class="sg-cb" :style="cssVars">
            <div v-if="!guard">
                <div class="sg-spin">
                    <div class="sg-spin-1"></div>
                    <div class="sg-spin-2"></div>
                </div>
                <p class="mt-2">Logging you in...</p>
            </div>
            <div v-else>
                <ascii-fish/>
                <div class="sg-error-msg">
                    <p class="mt-2">{{ guard }}</p>
                    <p class="mt-2">Please <a href="/login">login</a> again.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AsciiFish from './AsciiFish';

    export default {
        components: {
            AsciiFish
        },
        props: ['size', 'color', 'title'],
        computed: {
            guard: function() {
                return this.$stargate.guard.type === 'auth' ? this.$stargate.guard.msg : ''
            },
            cssVars() {
                return {
                    '--size': (this.size || 42) + 'px',
                    '--color': this.color || '#009E61',
                }
            }
        }
    }
</script>

<style scoped>
    .sg-cb {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .sg-error-msg {
        margin-top: 2em;
        text-align: center;
        padding: 10px;
    }
    .sg-spin {
        position: relative;
        width: var(--size); height: var(--size);
        margin: 0 auto;
    }
    .sg-spin-1,
    .sg-spin-2 {
        position: absolute; top: 0; left: 0;
        width: var(--size); height: var(--size);
        border-radius: 50%;
        background-color: var(--color);
        opacity: .55;
        animation: sg-anim 2.0s infinite ease-in-out;
    }
    .sg-spin-2 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }
    @keyframes sg-anim {
        0%, 100% {
            transform: scale(0.0);
        }
        50% {
            transform: scale(1.0);
        }
    }
</style>
