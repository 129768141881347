<template>
    <transition name="fade" :duration="{enter: 1.75, leave: 0}">
        <div id="modal-wrap" v-if="visible" @click="onClick">
            <transition name="fade" :duration="{enter: 1, leave: 0}">
                <div id="modal" :class="`modal-${type} modal-${name}`">
                    <div class="modal-body">
                        <div class="modal-title font-weight-bold">
                            <slot name="title"></slot>
                        </div>
                        <div class="modal-text">
                            <slot name="text"></slot>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <div v-if="type === 'prompt'">
                                <button type="button" class="btn btn-box modal-cancel">{{ btnCancel }}</button>
                                <button type="button" class="btn btn-box modal-confirm">{{ btnConfirm }}</button>
                            </div>
                            <div v-else>
                                <button type="button" class="btn btn-box modal-confirm">{{ btnConfirm }}</button>
                            </div>
                        </slot>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import Modal from "./index";
import vueI18n from '../../i18n/';

export default {
    props: {
        name: {
            required: true,
            type: String,
        },
        type: {
            default: 'prompt',
            type: String,
        },
        btnConfirm: {
            default: vueI18n().t('main.Confirm'),
            type: String,
        },
        btnCancel: {
            default: vueI18n().t('main.Cancel'),
            type: String,
        },
    },
    data() {
        return {
            visible: false,
        }
    },
    beforeMount() {
        Modal.vm.$on('show', this.init);

        document.body.addEventListener('keyup', this.onKeyUp, false);
    },
    beforeDestroy() {
        Modal.vm.$off('show', this.init);

        document.body.removeEventListener('keyup', this.onKeyUp, false);
    },
    methods: {
        /**
         * Render and display the modal.
         * @param {string} name
         * @param {{}} options
         * @return void
         */
        init(name, options = {}) {
            if (this.name === name) {
                this.options = options;
                this.open();
            }
        },
        /**
         * One event for all default elements.
         * @param {MouseEvent} e
         * @return void
         */
        onClick(e) {
            const target = e.target,
                targetClassList = target.classList;

            if (targetClassList.contains('modal-confirm')) {
                e.preventDefault();
                return this.confirm();
            }

            if (targetClassList.contains('modal-cancel') || target.id === 'modal-wrap') {
                e.preventDefault();
                return this.cancel();
            }
        },
        /**
         * Handle escape hit.
         * @param {KeyboardEvent} e
         * @return void
         */
        onKeyUp(e) {
            // `this.$el.querySelector` will only be defined if the component is rendered.
            if (e.key === 'Escape' && this.$el.querySelector) {
                this.cancel();
            }
        },
        open() {
            this.visible = true;
            this.$emit('opened', this.options);
        },
        cancel() {
            this.visible = false;
            this.$emit('closed', this.options);
        },
        confirm() {
            if (this.options.hideOnConfirm) {
                this.visible = false;
            }
            this.$emit('confirmed', this.getFormValues(), this.options);
        },
        /**
         * If a form is present in the modal, return its values.
         * @return {{}}
         */
        getFormValues() {
            const form = this.$el.querySelector('form'),
                iterator = form ? (new FormData(form)).entries() : [],
                values = {};

            for(let pair of iterator) {
                values[pair[0]] = pair[1];
            }

            return values;
        }
    }
}
</script>
