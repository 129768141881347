var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row align-items-center" }, [
      _c("div", { staticClass: "dashboard-nav-weeks-col col-11 p-0" }, [
        _c("div", { staticClass: "overflow-hidden position-relative" }, [
          _c(
            "ul",
            {
              staticClass: "nav",
              style: "width:" + 86 * _vm.weeks.length + "px"
            },
            _vm._l(_vm.weeks, function(week) {
              return _c("li", { key: week.code, staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    class: [
                      "nav-link",
                      "w" + week.code,
                      week.code === _vm.active.code ? "active" : ""
                    ],
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return function() {
                          return _vm.handleClick(week)
                        }.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "\n                                Week " +
                          _vm._s(week.number) +
                          "\n                                "
                      ),
                      week.code === _vm.current.code
                        ? _c(
                            "span",
                            { staticClass: "badge badge-pill badge-primary" },
                            [_vm._v("Current")]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ])
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-1 pr-0 text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            on: { click: _vm.setPreviousWeek }
          },
          [_vm._v("<")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            on: { click: _vm.setNextWeek }
          },
          [_vm._v(">")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }