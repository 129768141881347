var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex justify-content-between",
      attrs: { id: "app-footer" }
    },
    [
      _c("div", [
        _c("small", [
          _vm._v(
            "Movix Showtimes " +
              _vm._s(new Date().getFullYear()) +
              " (" +
              _vm._s(_vm.$config.version) +
              ")"
          )
        ])
      ]),
      _vm._v(" "),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("small", [
        _c(
          "a",
          {
            attrs: {
              href: "https://brightfish.be",
              target: "_blank",
              rel: "noopener"
            }
          },
          [_vm._v("brightfish.be")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }