import VueRouter from "vue-router";
import axios from "axios";
import {decodeJwtPayload, setHttpHeader, unsetHttpHeader} from "./helpers";
import {parseUrl} from "query-string";
import Callback from "./components/Callback";
import StargateError from "./StargateError";

const
    LOCATION = window.location.href,
    TOKEN_KEY = 'token',
    LOG_LEVELS = {
        'quiet': 0,
        'error': 1,
        'info': 2,
        'debug': 3,
    },
    DEFAULTS = {
        storage: null,
        router: null,
        baseUri: '',
        callbackPath: '',
        clientId: 0,
        User: null,
        routeAfterLogin: '/',
        routeAfterLogout: '/login',
        initialIntrospect: false,
        logLevel: 0
    };

export default class Stargate {
    constructor(Vue, options = {}) {
        this.loggedIn = false;

        this.options = Object.assign({}, DEFAULTS, options);

        // this.log('info', 'Instantiating Stargate', this);

        this._vm = _createStore(Vue);

        this.user = new this.options.User();

        this.isCompatible();

        let data = _getUserData.call(this);

        if (!data && !this.isCallbackPage()) {
            _setIntended.call(this);
        }

        _setupRouter(this);

        if (!data && this.isCallbackPage()) {
            _handleOAuthCallback.call(this)
            return;
        }

        if (data) {
            this.login(data.user, data.token, data.expires, true);
        }
    }

    /**
     * Log to console if allowed by options.
     * @param {string} level
     * @param {*} msg
     * @return void
     */
    log(level, ...msg) {
        if (this.options.logLevel >= LOG_LEVELS[level]) {
            if (level === 'error') console.error(...msg);
            console.dir(...msg);
            if (this.options.logLevel === 3) {
                console.trace();
            }
        }
    }

    /**
     * Getter.
     * @return {User|null}
     */
    get user() {
        return this._vm.user
    }

    /**
     * Setter.
     * @param {User|null} user
     */
    set user(user) {
        this._vm.user = user;
    }

    /**
     * Login the user.
     * @param {object} userData
     * @param {string} token
     * @param {number} expires
     * @param {boolean} exists
     * @return {Stargate}
     */
    login(userData, token, expires, exists = false) {
        setHttpHeader('Authorization', 'Bearer ' + token);

        this.user.onSgLogin(userData, token, expires, exists);

        this.loggedIn = true;

        return this
    }

    /**
     * Redirects after successful login.
     * @return {Stargate}
     */
    redirectAfterLogin() {
        this.options.router.push(this.options.storage.get('intended', true) || '/');
        return this
    }

    /**
     * Redirects at logout.
     * @return {Stargate}
     */
    redirectAfterLogout() {
        this.options.router.push(this.options.routeAfterLogout);
        return this
    }

    /**
     * Perform logout steps.
     * @return {Stargate}
     */
    logout() {
        unsetHttpHeader('Authorization');

        this.options.storage.delete(TOKEN_KEY);

        this.loggedIn = false;

        this.user.onSgLogout(TOKEN_KEY)

        this.user = null;

        this.redirectAfterLogout();

        return this
    }

    /**
     * Getter.
     * @return {Object}
     */
    get guard() {
        return this._vm.guard
    }

    /**
     * Setter.
     * @param {String} type
     * @param {String|Error} error
     */
    setError(type, error) {
        const errInst = error instanceof Error;

        this.log('error', error)

        this._vm.guard = {
            type: type,
            msg: errInst ? error.message : error,
            error: errInst ? error : null
        };
    }

    /**
     * Check if we are on the callback page.
     * @return {boolean}
     */
    isCallbackPage() {
        const pattern = this.options.callbackPath + '\\?code=[a-z0-9]{100,}';
        return (new RegExp(pattern)).test(LOCATION)
    }

    /**
     * Throws errors if Vue stack is not compatible.
     * @return boolean
     */
    isCompatible() {
        if (!this.options.clientId) {
            throw new StargateError('No client id is provided in the options!');
        }

        if (!this.options.router instanceof VueRouter) {
            throw new StargateError('The router can only be an instance of vue-router!');
        }

        if (!this.user.abilities) {
            throw new StargateError('The user object needs "abilities" property.');
        }

        if (!this.user.onSgLogin || !this.user.onSgLogout) {
            throw new StargateError('The user object needs "onSgLogin" and "onSgLogout" methods.');
        }

        return true;
    }
}

/**
 * Create a Vue instance with watchable data.
 * @param {Vue} Vue
 * @return {Object<Vue>}
 * @private
 */
function _createStore(Vue) {
    return new Vue({
        data: {
            user: null,
            guard: {
                type: 'route',
                msg: ''
            },
        },
    })
}

/**
 * Get the intended landing URL.
 * @return void
 * @private
 */
function _setIntended() {
    const path = window.location.pathname;
    let intended = !/^\/login/.test(path) ? path : this.options.routeAfterLogin;
    this.options.storage.set('intended', intended);
}

/**
 * Create the route and bind handler.
 * @param {Stargate} stargate
 * @private
 */
function _setupRouter(stargate) {
    // Define a view for the OAuth callback route
    stargate.options.router.addRoutes([{
        path: '/stargate/callback',
        component: Callback,
        meta: {
            stargateAuth: false
        }
    }]);

    // Handle permissions on routes
    stargate.options.router.beforeEach((to, from, next) => {
        _routerBeforeEach.call(stargate, to, from, next)
    });
}

/**
 * Check if the user as any of the given abilities.
 * @param {array} abilities
 * @param {User} user
 * @return {boolean}
 * @private
 */
function _userHasAbility(abilities, user) {
    return abilities.indexOf('*') > -1
        || abilities.filter(ab => user.abilities.includes(ab)).length;
}

/**
 * Reroute if authentication/authorisation fails.
 * @param {object} to
 * @param {object} from
 * @param {function} next
 * @return {*}
 * @private
 */
function _routerBeforeEach(to, from, next) {
    let logged = this.loggedIn;

    if (logged && to.meta.stargateAuth && to.meta.abilities) {
        if (_userHasAbility(to.meta.abilities, this.user)) {
            return next();
        }
        this.setError('route', 'You are not allowed there.');
        return next(false);
    }

    // Redirect to the login page, if the user is not logged and the route require authentication.
    if (!logged && to.name !== 'Login' && to.meta.stargateAuth) {
        next(false)
        return this.logout();
    }

    // If the user is logged and the requested route is the login, redirect.
    if (logged && /login/.test(to.path)) {
        return next(this.options.routeAfterLogin);
    }

    next()
}

/**
 * Try to return the stored user data.
 * @return {{user: {}, token: string, expires: number}|null}
 * @private
 */
function _getUserData() {
    const token = this.options.storage.get(TOKEN_KEY);

    if (!token) return null;

    let data = decodeJwtPayload(token);

    return {
        user: data,
        token: token,
        expires: this.options.storage.getTTL(TOKEN_KEY)
    }
}

/**
 * Store the token in the localStorage and the data.
 * @param {Storage} storage
 * @param {{access_token: String, expires_in: Number}} data
 * @return {{user: {}, token: string, expires: number}}
 * @private
 */
function _storeData(storage, data) {
    const exp = data.expires_in,
        token = data.access_token;

    storage.set(TOKEN_KEY, data.access_token, exp);

    delete data.access_token;
    delete data.expires_in;

    return {
        user: data,
        token: token,
        expires: exp
    };
}

/**
 * Last step in the (modified) authorisation code grant with PKCE.
 * Requests a token from the server.
 * @return void
 * @private
 */
function _handleOAuthCallback() {
    let storage = this.options.storage,
        state = storage.get('state', true),
        verifier = storage.get('verifier', true),
        params = parseUrl(LOCATION).query;

    if (!state || state !== params.state) {
        this.setError('auth', 'The authorisation request failed.');
        return;
    }

    axios
        .post(this.options.callbackPath, {
            verifier: verifier,
            code: params.code,
            introspect: this.options.initialIntrospect || false
        })
        .then(response => {
            if (!response.data.access_token) throw new StargateError('An access token is missing from the response.');
            let data = _storeData(storage, response.data);
            return Promise.resolve(data);
        })
        .then(data => this.login(data.user, data.token, data.expires).redirectAfterLogin())
        .catch(error => this.setError('auth', error));
}
