import axios from "axios";

const
    types = {
    },
    state = {
    },
    mutations = {
    },
    getters = {
    },
    actions = {
        /**
         * Search Movix for movies through the SHX API.
         * @param {function} commit
         * @param {object} state
         * @param {string} q
         * @return {Promise<array|null>}
         */
        searchMovies({commit, state}, q) {
            return axios
                .get('/movix/movies/search', {
                    params: {
                        q: q,
                    }
                })
                .then(response => Object.freeze(response.data.data))
        }
    };

export default {
    state,
    mutations,
    getters,
    actions
}
