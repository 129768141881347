<template>
    <div>
        <view-nav title="Playlist management" @nav="w => this.week = w">
            <template v-if="childRoute === 'Playlists'" v-slot:content="{ emit }">
                <weeks-nav @nav="emit"/>
            </template>
        </view-nav>

        <div class="view-content container-fluid">
            <div class="row">
                <div class="col-2">
                    <ul class="box nav">
                        <li class="nav-item w-100">
                            <router-link to="/playlists" exact-path class="nav-link">
                                <icon name="settings" class="red"></icon> Playlists
                            </router-link>
                        </li>
                        <li class="nav-item w-100">
                            <router-link to="/playlists/pings" exact-path class="nav-link">
                                <icon name="settings" class="red"></icon> Availability pings
                            </router-link>
                        </li>
                        <li class="nav-item w-100">
                            <router-link to="/playlists/confirmations" exact-path class="nav-link">
                                <icon name="settings"></icon> Reception confirmations
                            </router-link>
                        </li>
                    </ul>
                    <div class="box">
                        <div class="box-content">
                            <heading v-if="childRoute === 'Playlists'" upper="Help" border>
                                <template v-slot:lower><small>Imported weekly playlists.</small></template>
                            </heading>
                            <heading v-if="childRoute === 'PlaylistAvailablePings'" upper="Help" border>
                                <template v-slot:lower><small>Availability pings are sent by the playlist provider to notify Showtimes of new playlists to be downloaded.</small></template>
                            </heading>
                            <heading v-if="childRoute === 'PlaylistConfirmations'" upper="Help" border>
                                <template v-slot:lower><small>Playlist confirmations are sent by the exhibitor to inform the provider that playlists were well received and (partially) played.</small></template>
                            </heading>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <router-view :week="week"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Heading from "../partials/Heading";
import ViewNav from "../partials/ViewNav";
import WeeksNav from "../partials/WeeksNav";

export default {
    components: {
        Heading,
        ViewNav,
        WeeksNav
    },
    data() {
        return {
            week: null,
            childRoute: this.$route.name
        }
    },
    watch: {
        $route(to) {
            this.childRoute = to.name;
        },
    },
}
</script>
