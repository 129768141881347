<template>
    <div id="settings">
        <div class="row">
            <div class="col-4">
                <div class="box">
                    <settings-import job="sessions"
                                     :timestamp="latestImport.sessions"
                                     title="Import sessions"/>
                </div>
            </div>
            <div class="col-4">
                <div class="box">
                    <settings-import job="admissions"
                                     :timestamp="latestImport.admissions"
                                     title="Import admissions"/>
                </div>
            </div>
            <div class="col-4">
                <div class="box">
                    <settings-week-compilation />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <div class="box">
                    <settings-cached-import job="movix"
                                            :timestamp="latestImport.movix"
                                            title="Import Movix movies"/>
                </div>
            </div>
            <div class="col-4">
                <div class="box">
                    <settings-cached-import job="park"
                                            :timestamp="latestImport.park"
                                            title="Import Park theatres"/>
                </div>
            </div>
            <div class="col-4">
                <div class="box">
                    <settings-cached-import job="conductor"
                                            :timestamp="latestImport.conductor"
                                            title="Import Conductor slices"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SettingsImport from "../partials/SettingsImport";
import SettingsCachedImport from "../partials/SettingsCachedImport";
import SettingsWeekCompilation from "../partials/SettingsWeekCompilation";

export default {
    components: {
        SettingsWeekCompilation,
        SettingsCachedImport,
        SettingsImport,
    },
    asyncData({store}) {
        return Promise.all([
            store.dispatch('fetchTheaters'),
            store.dispatch('fetchSettings'),
        ]);
    },
    data() {
        const settings = this.$store.getters.settings;

        return {
            latestImport: {
                sessions: settings.latest_import.sessions,
                admissions: settings.latest_import.admissions,
                movix: settings.latest_import.movix,
                park: settings.latest_import.park,
                conductor: settings.latest_import.conductor,
            },
        }
    },
}
</script>
