var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c("spinner-bars", { staticClass: "mx-auto mt-4 mb-2" }),
      _vm._v(" "),
      _c("span", [_vm._v("Loading")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }