var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: {
        "enter-active-class": "fadeInUp",
        "leave-active-class": "fadeInDown"
      }
    },
    [
      _c(
        "div",
        {
          class: "toast toast-" + _vm.type + " " + (_vm.show ? "active" : ""),
          attrs: { role: "alert" },
          on: {
            mouseover: function($event) {
              return _vm.toggleTimer(true)
            },
            mouseleave: function($event) {
              return _vm.toggleTimer(false)
            }
          }
        },
        [
          _c("div", { staticClass: "toast-icon" }),
          _vm._v(" "),
          _c("div", {
            staticClass: "toast-text",
            domProps: { innerHTML: _vm._s(_vm.message || "&nbsp;") }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }