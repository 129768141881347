import axios from "axios";

const
    storage = window.localStorage,
    types = {
        THEATRES: 'theatres',
    },
    stored = storage.getItem(types.THEATRES),
    state = {
        theatres: stored ? Object.freeze(JSON.parse(stored)): null,
    },
    mutations = {
        [types.THEATRES](state, collection) {
            storage.setItem(types.THEATRES, JSON.stringify(collection));
            state.theatres = collection
        }
    },
    getters = {
        /**
         * Return all theatres.
         * @param {object} state
         * @return {array}
         */
        theatres: state => state.theatres,
        /**
         * Return the theatre for the given code.
         * @param {object} state
         * @param {object} getters
         * @return {function}
         */
        getTheatreByCode: (state, getters) => code => getters.theatres.find(t => t.code === code)
    },
    actions = {
        /**
         * Get the available theatres from Park through SHX.
         * @param {function} commit
         * @param {object} state
         * @return {Promise<array|null>}
         */
        fetchTheaters({commit, state}) {
            return state.theatres
                ? Promise.resolve(state.theatres)
                : axios
                    .get('/park/theatres')
                    .then(response => {
                        if (response.data.data.length) {
                            commit(types.THEATRES, Object.freeze(response.data.data));
                        }
                        return response.data.data
                    })
        }
    };

export default {
    state,
    mutations,
    getters,
    actions
}
