<template>
    <header id="app-header">
        <nav>
            <div>
                <a href="/">
                    <img height="38"
                         src="https://images.brightfish.be/apps/showtimes/showtimes-logo-blue.svg"
                         alt="Showtimes home"
                         class="mr-4"/>
                </a>
                <ul class="nav">
                    <li class="nav-item">
                        <router-link to="/dashboard" exact-path v-if="logged && user.can('manage_settings')"
                                     class="nav-link border-hover">Dashboard
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/sessions" exact-path v-if="logged && user.can('manage_settings')"
                                     class="nav-link border-hover">Sessions
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/movie-mapping" v-if="logged && user.can('manage_settings')"
                                     class="nav-link border-hover">Movix mapping
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/playlists" v-if="logged && user.can('manage_settings')"
                                     class="nav-link border-hover">Playlists
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/admin" v-if="logged && user.can('manage_settings')"
                                     class="nav-link border-hover">Admin
                        </router-link>
                    </li>
                    <li class="nav-item ml-4 overflow-hidden">
                        <toast class="nav-link"/>
                    </li>
                </ul>
            </div>
            <div>
                <div v-if="logged" class="user-menu">
                    <div class="user-nav">
                        <div class="user-img">
                            <img v-if="user.photo" :src="user.photo" :alt="user.username" class="w-100">
                            <span v-else>{{ user.username.charAt(0) }}</span>
                        </div>
                        <router-link to="/profile" class="border-hover">{{ $t('menu.Profile')}}</router-link>
                        <a href="#" class="border-hover" @click.prevent="logout">{{ $t('menu.Logout')}}</a>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
    import Toast from '../plugins/toast/Toast';

    export default {
        components: {
            Toast,
        },
        computed: {
            logged: function () {
                return this.$user.logged
            },
            user: function () {
                return this.$user
            },
        },
        methods: {
            logout() {
                this.$stargate.logout();
            }
        },
        watch: {
            '$stargate.guard'(o) {
                this.$toast.error(`${o.msg} (${o.type})`);
            }
        }
    }
</script>
