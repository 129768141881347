<template>
    <div class="locale-changer">
        <select v-model="locale">
            <option v-for="(label, lang) in locales" :key="`locale-${lang}`" :value="lang">
                {{ label }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'locale-changer',
    data() {
        return {
            locales: this.$config.locales,
            locale: this.$config.defaultLocale
        }
    },
    watch: {
        locale(lang) {
            // The App component watches locale changes, {@see app.js}
            this.$store.dispatch('setLocale', lang)
        },
    }
}
</script>
