import Stargate from "./Stargate.js";
import Ls from "@dotburo/ls-ttl";
import {generateCodeChallenge, randomString, rTrimString} from "./helpers";
import {stringifyUrl} from "query-string";

const storage = new Ls({
        keyPrefix: 'sg-',
        encrypt: btoa.bind(window)
    }),
    CLIENT_ID = window.stargate.clientId || 0,
    BASE_URI = window.stargate.baseUri || 'https://stargate.bybrightfish.be',
    CALLBACK_PATH = '/stargate/callback';

export default (() => {

    return function install(Vue, options) {

        options.storage = storage;
        options.clientId = options.clientId || CLIENT_ID;
        options.baseUri = rTrimString(options.baseUri || BASE_URI, '/');
        options.callbackPath = rTrimString(options.callbackPath || CALLBACK_PATH, '/');
        options.initialIntrospect = options.initialIntrospect || false;

        let instance = new Stargate(Vue, options);

        Object.defineProperties(Vue.prototype, {
            $user: {
                get() {
                    return instance.user;
                }
            },
            $stargate: {
                get: function () {
                    instance.createAuthorisationUrl = () => createAuthorisationUrl(options);
                    return instance;
                }
            }
        });
    }
})();

/**
 * Static module method to generate a authorisation url.
 * @param {object} options
 * @return {string}
 */
function createAuthorisationUrl(options) {
    const state = randomString(40),
        codeVerifier = randomString(128);

    storage.set('state', state, 60);
    storage.set('verifier', codeVerifier, 60);

    return stringifyUrl({
        url: options.baseUri + '/oauth/authorize',
        query: {
            client_id: options.clientId,
            redirect_uri: window.location.origin + options.callbackPath,
            response_type: 'code',
            state: state,
            code_challenge: generateCodeChallenge(codeVerifier),
            code_challenge_method: 'S256',
            scope: '*',
        }
    })
}
