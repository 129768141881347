import dayjs from "dayjs";

export default {
    methods: {
        downloadJsonData(name, data) {
            const downloadLink = document.createElement('a'),
                json = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, null, 4));

            this.$el.appendChild(downloadLink);

            downloadLink.setAttribute('href', json);
            downloadLink.setAttribute('download', name + '-' + dayjs().unix() + '.json');
            downloadLink.click();

            downloadLink.parentNode.removeChild(downloadLink);
        },
    }
}
