<template>
    <div>
        <view-nav title="Dashboard" @nav="w => this.week = w">
            <template v-slot:content="{ emit }">
                <weeks-nav @nav="emit"/>
            </template>
        </view-nav>

        <div class="view-content container-fluid">
            <div class="row">
                <div class="col-2">
                    <div v-if="stats.length" class="box">
                        <b-form-select v-model="dashboardType"
                                  :options="dashboardTypes"
                                  @change="onDashboardChange"
                                  class="w-100"/>
                    </div>
                    <div v-if="stats.length" class="box">
                        <div class="box-content">
                            <heading :upper="'Total ' + statsMeta.type"
                                     :lower="statsMeta.total"
                                     border/>
                            <heading v-if="statsMeta.type === 'sessions'"
                                     upper="Theatres ready"
                                     :lower="`${localStats.ready} (${localStats.readyPercent}%)`"
                                     border/>
                            <heading v-else
                                     upper="Total"
                                     :lower="`${localStats.totalItems}`"
                                     border/>
                        </div>
                    </div>
                    <div v-if="statsMeta.imported_at" class="box">
                        <div class="box-content">
                            <heading upper="Last import"
                                     :lower="formatDate(statsMeta.imported_at)"
                                     border/>
                        </div>
                    </div>
                </div>

                <div class="col-10">
                    <div class="box">
                        <div v-if="stats.length" class="box-content">
                            <ul class="stats-list">
                                <li v-for="(stat, i) in stats" :key="i" class="mb-3 border-bottom">
                                    <dashboard-session-stats-item v-if="dashboardType === 'sessions'"
                                                                  :data="stat"
                                                                  :max="statsMeta.max"
                                                                  :week="week.code"/>
                                    <dashboard-stats-item v-else
                                                          :data="stat"
                                                          :max="statsMeta.max"/>
                                </li>
                            </ul>
                        </div>
                        <div v-else class="box-content">
                            <p>No data for this filters.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WeeksNav from "../partials/WeeksNav";
    import store from "../store/index";
    import Heading from "../partials/Heading";
    import helpers from "../helpers/helpers";
    import {BFormSelect} from "bootstrap-vue";
    import DashboardStatsItem from "../partials/DashboardStatsItem";
    import DashboardSessionStatsItem from "../partials/DashboardSessionStatsItem";
    import ViewNav from "../partials/ViewNav";
    import dayjs from "dayjs";

    export default {
        components: {
            DashboardStatsItem,
            DashboardSessionStatsItem,
            Heading,
            WeeksNav,
            BFormSelect,
            ViewNav,
        },
        data() {
            return {
                week: null,
                dashboardType: helpers.getCurrentUrlQuery().type || 'sessions',
                dashboardTypes: [],
                stats: [],
                statsMeta: {},
                localStats: {},
            }
        },
        asyncData({store}) {
            return Promise.all(
                [store.dispatch('fetchSlices')]
            );
        },
        watch: {
            dashboardType(type) {
                this.replaceUrl(this.week);
                this.fetchStats(this.week, type);
            },
            week(week) {
                this.replaceUrl(week);
                this.fetchStats(week, this.dashboardType);
            }
        },
        methods: {
            onDashboardChange(value) {
                this.dashboardType = value;
            },
            fetchStats(week, type) {
                return store.dispatch('fetchStats', week.code)
                    .then(data => {
                        this.dashboardTypes = this.setDashboardTypes(data);
                        this.statsMeta = data[type].meta;
                        this.stats = data[type].stats;
                        this.localStats = this.calcLocalStats(type, data[type]);
                    });
            },
            setDashboardTypes(stats) {
                return Object.keys(stats).map(type => ({value: type, text: stats[type].meta.title}));
            },
            calcLocalStats(type, data) {
                if (type === 'sessions') {
                    const ok = [].concat(data.stats.filter(s => s.count)).length
                    return {
                        ready: ok + '/' + data.meta.total_items,
                        readyPercent: ((ok / data.meta.total_items) * 100).toFixed(1),
                    }
                }
                return {
                    totalItems: data.meta.total_items + (type === 'admissions-by-movie' ? ' movies' : ' theatres'),
                }
            },
            replaceUrl(week) {
                this.$router.replace({
                    name: 'Dashboard',
                    query: {type: this.dashboardType, week: week.code}
                })
            },
            formatDate(timestamp) {
                return dayjs(timestamp * 1000).format('DD/MM/YYYY HH:mm:ss')
            }
        }
    }
</script>
