var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box-content" },
    [
      _c("heading", {
        attrs: { border: "" },
        scopedSlots: _vm._u([
          {
            key: "upper",
            fn: function() {
              return [
                _c("span", [_vm._v("Recalculate weekly admissions")]),
                _vm._v(" "),
                _vm.running
                  ? _c("spinner-bars")
                  : _vm.done
                  ? _c("span", { staticStyle: { color: "green" } }, [
                      _vm._v("✓")
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "b-input-group",
            {
              attrs: { size: "sm", prepend: "for" },
              scopedSlots: _vm._u([
                {
                  key: "append",
                  fn: function() {
                    return [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "primary" },
                          on: { click: _vm.runImport }
                        },
                        [_vm._v("Calculate")]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("b-form-input", {
                attrs: { placeholder: "Weeks" },
                model: {
                  value: _vm.weeks,
                  callback: function($$v) {
                    _vm.weeks = $$v
                  },
                  expression: "weeks"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("b-form-text", [
        _vm._v("Insert a comma separated list of week codes.")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }