var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "admin-logs" } }, [
    _c(
      "div",
      { staticClass: "box view-nav-bar" },
      [
        _c("multi-select", {
          attrs: {
            options: _vm.$config.errorLevels,
            placeholder: "Level filter",
            selectLabel: "",
            taggable: true,
            multiple: true,
            searchable: false
          },
          model: {
            value: _vm.filterLevels,
            callback: function($$v) {
              _vm.filterLevels = $$v
            },
            expression: "filterLevels"
          }
        }),
        _vm._v(" "),
        _c("multi-select", {
          attrs: {
            options: _vm.$config.serviceContexts,
            placeholder: "Context filter",
            selectLabel: "",
            taggable: true,
            multiple: true,
            searchable: false
          },
          model: {
            value: _vm.filterContexts,
            callback: function($$v) {
              _vm.filterContexts = $$v
            },
            expression: "filterContexts"
          }
        }),
        _vm._v(" "),
        _vm.totalRows !== null
          ? _c("b-pagination", {
              attrs: {
                "total-rows": _vm.totalRows,
                "per-page": _vm.perPage,
                "aria-controls": "data-table"
              },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "box" }, [
      _c(
        "div",
        { staticClass: "box-content pt-3" },
        [
          _c("b-table", {
            ref: "dataTable",
            staticClass: "shx-table w-100",
            attrs: {
              id: "data-table",
              hover: "",
              dark: "",
              fixed: "",
              striped: "",
              "sort-icon-left": "",
              fields: _vm.columns,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              currentPage: _vm.currentPage,
              perPage: 0,
              items: _vm.fetchData
            },
            on: {
              "update:sortBy": function($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function($event) {
                _vm.sortDesc = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "cell(created_at)",
                fn: function(data) {
                  return [
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          _vm
                            .$dayjs(data.item.created_at)
                            .format("DD/MM/YYYY HH:mm:ss.SSS")
                        )
                      )
                    ])
                  ]
                }
              },
              {
                key: "cell(context)",
                fn: function(data) {
                  return [_c("small", [_vm._v(_vm._s(data.item.context))])]
                }
              },
              {
                key: "cell(subject)",
                fn: function(data) {
                  return [
                    _c("div", [
                      _c(
                        "span",
                        { class: "mr-2 badge badge-" + data.item.level },
                        [_vm._v(_vm._s(data.item.level))]
                      ),
                      _vm._v(
                        "\n                        " +
                          _vm._s(data.item.subject || data.item.body) +
                          "\n                    "
                      )
                    ])
                  ]
                }
              },
              {
                key: "cell(actions)",
                fn: function(data) {
                  return [
                    data.item.gauges.length ||
                    (data.item.body && data.item.subject)
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-link",
                            on: {
                              click: function() {
                                return data.toggleDetails()
                              }
                            }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                name:
                                  "chevron-" +
                                  (data.detailsShowing ? "up" : "down")
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "row-details",
                fn: function(data) {
                  return [
                    data.item.body
                      ? _c("p", {
                          staticClass: "text-muted",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$helpers.nl2br(data.item.body)
                            )
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    data.item.gauges.length
                      ? _c(
                          "ul",
                          _vm._l(data.item.gauges, function(metric) {
                            return _c("li", { key: metric.id }, [
                              _c("span", [_vm._v(_vm._s(metric.key))]),
                              _vm._v(":\n                            "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(metric.value + (metric.unit || ""))
                                )
                              ])
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box" },
      [
        _vm.totalRows !== null
          ? _c("b-pagination", {
              attrs: {
                "total-rows": _vm.totalRows,
                "per-page": _vm.perPage,
                "aria-controls": "data-table"
              },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }