var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("h4", { class: ["h-label", _vm.border ? "" : "border-0"] }, [
    _c(
      "span",
      { staticClass: "upper" },
      [
        _vm._t("upper", function() {
          return [_vm._v(_vm._s(_vm.upper))]
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "lower" },
      [
        _vm._t("lower", function() {
          return [_vm._v(_vm._s(_vm.lower))]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }