var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "settings" } }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("settings-import", {
              attrs: {
                job: "sessions",
                timestamp: _vm.latestImport.sessions,
                title: "Import sessions"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("settings-import", {
              attrs: {
                job: "admissions",
                timestamp: _vm.latestImport.admissions,
                title: "Import admissions"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "box" }, [_c("settings-week-compilation")], 1)
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("settings-cached-import", {
              attrs: {
                job: "movix",
                timestamp: _vm.latestImport.movix,
                title: "Import Movix movies"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("settings-cached-import", {
              attrs: {
                job: "park",
                timestamp: _vm.latestImport.park,
                title: "Import Park theatres"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("settings-cached-import", {
              attrs: {
                job: "conductor",
                timestamp: _vm.latestImport.conductor,
                title: "Import Conductor slices"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }