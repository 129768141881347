var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-nav" }, [
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col-2" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-10" },
          [
            _vm._t("content", null, {
              emit: function(x, y, z) {
                return _vm.$emit("nav", x, y, z)
              }
            })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }