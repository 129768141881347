var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pre",
    {
      staticStyle: {
        "font-size": "13px",
        "line-height": "16px",
        width: "250px",
        "white-space": "pre-wrap",
        margin: "0 auto"
      }
    },
    [
      _vm._v(
        "                       .∙¨∙.\n          .':clllllc;';,    ,:.\n.;'    .:x00xl;;;;:lk0XO;.  `°´\n ,kk;,oKWWx'   .',.  ,OWW0l.\n  cNWNMMMk.  .:dOXKl. '0MMWk'\n  ;XMMMMWo  ;0NXXWMK, .xMWKkd,\n  lNXKWMMO' .l0KXXOc. ,0MWXk:\n ;xo'.;kXWO;.  ¨¨¨  .c0WNOc.\n.,.     'cxOkdlcccldO0kl,.\n           .';:ccc:;'.\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }