var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("view-nav", {
        attrs: { title: "Session management" },
        on: {
          nav: function(w) {
            return (this$1.week = w)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function(ref) {
              var emit = ref.emit
              return [_c("weeks-nav", { on: { nav: emit } })]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("div", { staticClass: "view-content container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2" }, [
            _c("ul", { staticClass: "box nav" }, [
              _c(
                "li",
                { staticClass: "nav-item w-100" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      attrs: { to: "/sessions", "exact-path": "" }
                    },
                    [
                      _c("icon", {
                        staticClass: "red",
                        attrs: { name: "settings" }
                      }),
                      _vm._v(" Sessions by lineup\n                        ")
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c(
                "div",
                { staticClass: "box-content" },
                [
                  _vm.childRoute === "SessionsByLineup"
                    ? _c("heading", {
                        attrs: { upper: "Help", border: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "lower",
                              fn: function() {
                                return [
                                  _c("small", [_vm._v(_vm._s(_vm.helpText))])
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          735836407
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-10" },
            [_c("router-view", { attrs: { week: _vm.week } })],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }