var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    attrs: { name: _vm.name },
    on: {
      opened: _vm.onOpened,
      confirmed: _vm.onConfirm,
      closed: _vm.resetModal
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_c("b", [_vm._v("Search Movix")])]
        },
        proxy: true
      },
      {
        key: "text",
        fn: function() {
          return [
            _c(
              "b-input-group",
              { staticClass: "mb-3" },
              [
                _c("b-form-input", {
                  attrs: { placeholder: "Search Movix" },
                  on: { keydown: _vm.onSearchEnter },
                  model: {
                    value: _vm.term,
                    callback: function($$v) {
                      _vm.term = $$v
                    },
                    expression: "term"
                  }
                }),
                _vm._v(" "),
                _c(
                  "b-input-group-append",
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: { click: _vm.onClickSearch }
                      },
                      [_vm._v("Search")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.loading
              ? _c("div", [_vm._v("searching...")])
              : _vm.searched
              ? _c(
                  "form",
                  { staticStyle: { overflow: "scroll", "max-height": "40vh" } },
                  [
                    _vm.movies
                      ? _c(
                          "div",
                          [
                            _vm._l(_vm.movies, function(movie, i) {
                              return _c(
                                "div",
                                { key: i },
                                [
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-control custom-radio"
                                      },
                                      [
                                        _c("input", {
                                          staticClass: "custom-control-input",
                                          attrs: {
                                            type: "radio",
                                            id: "mcr-" + (i + 1),
                                            name: "movie"
                                          },
                                          domProps: { value: movie.id }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "custom-control-label",
                                            attrs: { for: "mcr-" + (i + 1) }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(movie.title) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "text-muted" },
                                              [_vm._v(_vm._s(movie.id))]
                                            ),
                                            _vm._v(
                                              " (" +
                                                _vm._s(movie.release_year) +
                                                ")\n                            "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "https://movix.brightfish.be/movies/" +
                                                movie.id,
                                              target: "_blank",
                                              rel: "noopener"
                                            }
                                          },
                                          [
                                            _c("icon", {
                                              attrs: { name: "external-link" }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                ],
                                2
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "custom-control custom-radio" },
                              [
                                _c("input", {
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    type: "radio",
                                    id: "mcr-0",
                                    name: "movie",
                                    checked: "",
                                    value: ""
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "custom-control-label text-muted",
                                    attrs: { for: "mcr-0" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("main.NoneMatching")))]
                                )
                              ]
                            )
                          ],
                          2
                        )
                      : _c("div", [_c("p", [_vm._v("No movies found.")])])
                  ]
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }