<template>
    <modal :name="name" @opened="onOpened" @confirmed="onConfirm" @closed="resetModal">
        <template v-slot:title><b>Search Movix</b></template>
        <template v-slot:text>
            <b-input-group class="mb-3">
                <b-form-input v-model="term" placeholder="Search Movix" v-on:keydown="onSearchEnter"/>
                <b-input-group-append>
                    <b-button v-on:click="onClickSearch" variant="primary">Search</b-button>
                </b-input-group-append>
            </b-input-group>

            <div v-if="loading">searching...</div>
            <form v-else-if="searched" style="overflow: scroll; max-height: 40vh">
                <div v-if="movies">
                    <div v-for="(movie, i) in movies" :key="i">
                        <template>
                            <div class="custom-control custom-radio">
                                <input type="radio" :id="`mcr-${i + 1}`" name="movie" class="custom-control-input" :value="movie.id">
                                <label class="custom-control-label" :for="`mcr-${i + 1}`">
                                    {{ movie.title }} <span class="text-muted">{{ movie.id }}</span> ({{ movie.release_year }})
                                </label>
                                <a :href="`https://movix.brightfish.be/movies/${movie.id}`" target="_blank" rel="noopener">
                                    <icon name="external-link"></icon>
                                </a>
                            </div>
                        </template>
                    </div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="mcr-0" name="movie" class="custom-control-input" checked value="">
                        <label class="custom-control-label text-muted" for="mcr-0">{{ $t('main.NoneMatching') }}</label>
                    </div>
                </div>
                <div v-else>
                    <p>No movies found.</p>
                </div>
            </form>
        </template>
    </modal>
</template>

<script>
import Modal from "../plugins/modal/Modal";
import {BInputGroup, BInputGroupAppend, BFormInput, BButton} from "bootstrap-vue";

export default {
    components: {
        Modal,
        BInputGroup,
        BInputGroupAppend,
        BFormInput,
        BButton,
    },
    props: ['name'],
    data() {
        // These properties do not need to be reactive.
        this.term = ''
        this.movies = null;

        // Reactive props.
        return {
            loading: false,
            searched: false,
        }
    },
    methods: {
        /**
         * Set the source_title as default search string.
         * @return void
         */
        onOpened(options) {
            this.search(this.term = options.term);
        },
        /**
         * Launch the search.
         * @return void
         */
        onClickSearch(e) {
            this.search(this.term);
            e.stopPropagation();
        },
        /**
         * Launch the search on hitting Enter.
         * @return void
         */
        onSearchEnter(e) {
            if (e.key === 'Enter') {
                this.search(this.term);
            }
            e.stopPropagation();
        },
        /**
         * Bubble up confirmation event and reset the component.
         * @return void
         */
        onConfirm(formData, options) {
            const selectedId = parseInt(formData.movie, 10);

            if (!selectedId) {
                this.resetModal();
                return;
            }

            const movie = this.movies.find(movie => movie.id === selectedId);

            this.$emit('confirmed', movie, options);

            this.resetModal();
        },
        /**
         * Fetch movies directly from Movix.
         * @param {string} title
         * @return {Promise<void>}
         */
        search(title) {
            this.loading = true;

            return this.$store.dispatch('searchMovies', title)
                .then(movies => {
                    this.searched = true;
                    this.loading = false;
                    this.movies = movies;
                })
                .catch(() => {
                    this.searched = true;
                    this.loading = false;
                    this.movies = null;
                })
        },
        /**
         * Reinitialize the modal's data.
         * @return void
         */
        resetModal() {
            this.term = '';
            this.searched = this.loading = false;
            this.movies = null;
        }
    }
}
</script>
