var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    attrs: { name: _vm.name, type: "simple", "btn-confirm": "OK" },
    on: { opened: _vm.onOpen, closed: _vm.onClose },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _c("b", [
              _vm._v("Recent " + _vm._s(_vm.type) + " for "),
              _c("span", { staticClass: "font-italic" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "text",
        fn: function() {
          return [
            _vm.loading
              ? _c("div", [_c("spinner-bars")], 1)
              : _vm.records.length
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      "\n                These are the 5 most recent " +
                        _vm._s(_vm.type) +
                        " for "
                    ),
                    _c("span", { staticClass: "font-italic text-nowrap" }, [
                      _vm._v(_vm._s(_vm.title))
                    ]),
                    _vm._v(
                      ".\n                The links provided for each record will help you finding more info about the movie.\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "ol",
                    _vm._l(_vm.records, function(data, i) {
                      return _c("li", { key: i }, [
                        _vm._v(
                          "\n                    On " +
                            _vm._s(
                              data.day + (data.time ? " at " + data.time : "")
                            ) +
                            " in\n                    "
                        ),
                        data.url
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: data.url,
                                  target: "_blank",
                                  rel: "noopener"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(data.theatre) +
                                    "\n                    "
                                )
                              ]
                            )
                          : _c("span", [_vm._v(_vm._s(data.theatre))])
                      ])
                    }),
                    0
                  )
                ])
              : _c("div", [_c("p", [_vm._v("No data found for this title.")])])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }