var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("search-movix-modal", {
        attrs: { name: "movie-matcher" },
        on: { confirmed: _vm.replaceMovixId }
      }),
      _vm._v(" "),
      _c("mapping-info-modal", { attrs: { name: "mapping-info" } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box view-nav-bar" },
        [
          _c("b-form-select", {
            staticStyle: { width: "210px" },
            attrs: { options: _vm.availableSources },
            model: {
              value: _vm.source,
              callback: function($$v) {
                _vm.source = $$v
              },
              expression: "source"
            }
          }),
          _vm._v(" "),
          _c("b-form-input", {
            staticClass: "transparent-input",
            attrs: {
              type: "search",
              debounce: "400",
              placeholder: "Search by source title",
              autofocus: ""
            },
            model: {
              value: _vm.searchTerm,
              callback: function($$v) {
                _vm.searchTerm = $$v
              },
              expression: "searchTerm"
            }
          }),
          _vm._v(" "),
          _vm.totalRows !== null
            ? _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  "aria-controls": "unknown-movies-table"
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-content pt-3" }, [
          _c(
            "div",
            [
              _c("b-table", {
                ref: "dataTable",
                staticClass: "shx-table w-100",
                attrs: {
                  id: "data-table",
                  hover: "",
                  dark: "",
                  fixed: "",
                  "sort-icon-left": "",
                  filter: _vm.searchTerm,
                  fields: _vm.columns,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  currentPage: _vm.currentPage,
                  perPage: 0,
                  items: _vm.fetchData
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.sortDesc = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(session_datetime)",
                    fn: function(row) {
                      return [
                        row.item.ctx === "session-import"
                          ? _c("small", {
                              domProps: {
                                innerHTML: _vm._s(
                                  row.item.session_datetime
                                    .replace(/:00$/, "")
                                    .replace(" ", "&nbsp;")
                                )
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(movix_title)",
                    fn: function(row) {
                      return [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://movix.brightfish.be/movies/" +
                                row.item.movix_id +
                                "/edit",
                              target: "_blank",
                              rel: "noopener"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(row.item.movix_title || row.item.movix_id)
                            )
                          ]
                        ),
                        _vm._v(" "),
                        row.item.movix_title
                          ? _c("small", { staticClass: "text-muted" }, [
                              _vm._v(_vm._s(row.item.movix_id))
                            ])
                          : _c("span", { staticClass: "text-warning" }, [
                              _vm._v("NO MOVIX META DATA")
                            ]),
                        _vm._v(" "),
                        row.item.similarity !== null
                          ? _c(
                              "div",
                              { staticClass: "progress-bar-wrap" },
                              [
                                _c("b-progress-bar", {
                                  style: _vm.getTitleSimilarityClipPath(
                                    row.item
                                  ),
                                  attrs: { value: 1, max: 1, default: "" }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(movix_release_date)",
                    fn: function(row) {
                      return [
                        row.item.movix_release_date
                          ? _c(
                              "span",
                              {
                                class: _vm.isMappedOnOldMovie(row.item)
                                  ? "text-warning"
                                  : ""
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(row.item.movix_release_date) +
                                    "\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(movix_target_packs)",
                    fn: function(row) {
                      return [
                        _c(
                          "div",
                          _vm._l(row.item.movix_target_packs, function(tag) {
                            return _c(
                              "span",
                              {
                                key: tag,
                                staticClass: "badge badge-info mr-1"
                              },
                              [_vm._v(_vm._s(tag))]
                            )
                          }),
                          0
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(counts)",
                    fn: function(row) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$tc("plural.session", row.item.count)) +
                              " in " +
                              _vm._s(
                                _vm.$tc(
                                  "plural.theatre",
                                  row.item.theatre_count
                                )
                              )
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "cell(actions)",
                    fn: function(data) {
                      return [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-1",
                            attrs: { size: "sm" },
                            on: {
                              click: function() {
                                return _vm.showInfo(data.item)
                              }
                            }
                          },
                          [_vm._v("🛈")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: { size: "sm" },
                            on: {
                              click: function() {
                                return _vm.matchMovie(data.item)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                            Remap\n                        "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box" },
        [
          _vm.totalRows !== null
            ? _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  "aria-controls": "playlist-confirmations-table"
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }