import config from '../config';

const
    state = {
        locale: config.defaultLocale,
    },
    mutations = {
        locale(state, locale) {
            state.locale = locale;
        }
    },
    getters = {
        locale: state => state.locale
    },
    actions = {
        setLocale({commit}, locale) {
            commit('locale', locale);
        }
    };

export default {
    state,
    mutations,
    getters,
    actions
}
