<template>
    <div>
        <div class="box view-nav-bar">
            <b-form-input v-model="searchTerm"
                          class="transparent-input"
                          type="search"
                          debounce="400"
                          placeholder="Search"/>

            <multi-select v-model="theatre"
                          :options="theatres"
                          placeholder="Theatre"
                          selectLabel=""
                          track-by="code"
                          label="name"
                          :searchable="true"/>

            <b-pagination v-if="totalRows !== null"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="data-table"/>
        </div>

        <div class="box">
            <div :class="'box-content pt-3' + (totalRows ? '' : ' d-none')">
                <b-table ref="dataTable"
                         id="data-table"
                         class="shx-table w-100"
                         hover dark fixed
                         sort-icon-left
                         :filter="searchTerm"
                         :fields="columns"
                         :sort-by.sync="sortBy"
                         :sort-desc.sync="sortDesc"
                         :currentPage="currentPage"
                         :perPage="0"
                         :items="fetchData">

                    <template #cell(status)="row">
                        <div class="badge badge-info">{{ row.item.status }}</div>
                    </template>
                </b-table>
            </div>

            <div v-if="!totalRows" class="box-content pt-3 text-center text-muted">No playlist pings yet.</div>
        </div>

        <div class="box">
            <b-pagination v-if="totalRows !== null"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="data-table"/>
        </div>
    </div>
</template>

<script>
    import {BButton, BFormInput, BPagination, BTable} from "bootstrap-vue";
    import axios from "axios";
    import helpers from "../helpers/helpers";
    import Modal from "../plugins/modal/Modal";
    import MultiSelect from "vue-multiselect";
    import dataTableLoading from "../mixins/data-table-loading";

    export default {
        components: {
            BButton,
            BFormInput,
            BPagination,
            BTable,
            Modal,
            MultiSelect,
        },
        mixins: [
            dataTableLoading
        ],
        asyncData({store}) {
            return Promise.all([
                store.dispatch('fetchTheaters'),
            ]);
        },
        data() {
            const params = helpers.getCurrentUrlQuery(),
                store = this.$store.getters;

            this.theatres = store.theatres;

            this.columns = [
                { key: 'theatre_code', label: 'Theatre', thClass: 'shx-th-80', sortable: true },
                { key: 'status', thClass: 'shx-th-100', sortable: true },
                { key: 'playlists_count', label: 'Playlist Count' },
                { key: 'playlists_scheduled_at', label: 'Scheduled at', formatter: helpers.tableDatetime },
                { key: 'playlists_delivered_at', label: 'Delivered at', formatter: helpers.tableDatetime },
                { key: 'session_first', label: 'First session', formatter: helpers.tableDatetime },
                { key: 'session_last', label: 'Last session', formatter: helpers.tableDatetime },
                { key: 'created_by', label: 'Created By', sortable: true },
                { key: 'created_at', label: 'Created At', sortable: true, formatter: helpers.tableDatetime },
            ];

            return {
                fetching: false,
                theatre: params.theatre ? store.getTheatreByCode(params.theatre) : '',
                searchTerm: params.term || '',
                currentPage: params.page || 1,
                perPage: this.$config.tableRows,
                totalRows: null, // do not set to `0` before data loaded, it otherwise it resets `currentPage` to `1`
                sortBy: params.sortBy || 'created_at',
                sortDesc: params.sort ? params.sort === 'desc' : true,
            }
        },
        watch: {
            theatre() {
                this.currentPage = 1;
                this.$refs.dataTable.refresh();
            },
        },
        methods: {
            fetchData(ctx, callback) {
                this.tableLoading(true);

                axios.get(`/playlists/pings`, {
                    params: {
                        theatre: this.theatre ? this.theatre.code : null,
                        term: this.searchTerm,
                        orderBy: ctx.sortBy,
                        order: ctx.sortDesc ? 'desc' : 'asc',
                        page: ctx.currentPage,
                    }
                }).then(response => {
                    callback(response.data.data.data);
                    this.totalRows = response.data.data.total;
                }).then(() => {
                    this.tableLoading(false).replaceCurrentUri(ctx);
                }).catch(() => {
                    this.tableLoading(false).replaceCurrentUri(ctx);
                })
            },
            replaceCurrentUri(ctx) {
                this.$router.replace({
                    name: 'PlaylistAvailablePings',
                    query: {
                        ...this.theatre && {theatre: this.theatre.code},
                        ...this.searchTerm && {term: this.searchTerm},
                        sortBy: ctx.sortBy,
                        sort: ctx.sortDesc ? 'desc' : 'asc',
                        page: ctx.currentPage,
                    }
                })
            },
            tableDatetime(value) {
                return this.$dayjs(value).format('DD/MM/YYYY HH:mm:ss');
            }
        }
    }
</script>
