var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-table", {
    ref: "dataTable",
    staticClass: "shx-table w-100",
    attrs: {
      id: "data-table",
      dark: "",
      fixed: "",
      borderless: "",
      small: "",
      "sort-icon-left": "",
      fields: _vm.columns,
      "sort-by": "session_day",
      "sort-desc": false,
      currentPage: _vm.currentPage,
      perPage: 0,
      items: _vm.sessions
    },
    scopedSlots: _vm._u([
      {
        key: "cell(session_day)",
        fn: function(data) {
          return [
            _vm._v(
              "\n        " +
                _vm._s(data.item.session_day) +
                " " +
                _vm._s(data.item.session_time) +
                "\n        "
            ),
            _c("span", { staticClass: "text-muted" }, [
              _vm._v("[" + _vm._s(data.item.screen_number) + "]")
            ])
          ]
        }
      },
      {
        key: "cell(source_title)",
        fn: function(data) {
          return [
            data.item.movix_id
              ? _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://movix.brightfish.be/movies/" +
                        data.item.movix_id,
                      target: "_blank",
                      rel: "noopener"
                    }
                  },
                  [_vm._v(_vm._s(data.item.source_title))]
                )
              : _c("span", [_vm._v(_vm._s(data.item.source_title))]),
            _vm._v("\n        [" + _vm._s(data.item.movix_id) + "]\n    ")
          ]
        }
      },
      {
        key: "cell(session_type)",
        fn: function(data) {
          return [
            _c("span", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(data.item.session_type))
            ]),
            _vm._v(", " + _vm._s(data.item.session_lang) + "\n    ")
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }