<template>
    <div :class="`${data.count ? '': 'text-danger'}`">
        <div class="stats-title">
            <span class="text-muted">{{ data.label_1 }}</span>
            <h4 class="stats-title mb-0">
                <span v-if="data.count" class="text-success">✔</span>
                <span v-else>⚡</span>
                <span>{{ data.label_2 }}</span>
            </h4>
        </div>
        <div class="stats-bar mt-2" v-bind:style="{width: ((data.count / max) * 100 || 0) + '%'}"></div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'max'],
    }
</script>
