<template>
    <div class="box-content">
        <heading border>
            <template v-slot:upper>
                <span>{{ title }}</span>
                <spinner-bars v-if="running"/>
                <span v-else>{{ time.format('DD/MM/YYYY HH:mm:ss') }}</span>
            </template>
        </heading>
        <div class="d-flex">
            <b-input-group size="sm" prepend="for">
                <b-form-select v-model="theatre"
                               :options="theatres"
                               size="sm"
                               @change="onTheatreChange"/>
                <template #append>
                    <b-button size="sm" @click="runImport" variant="primary">Import</b-button>
                </template>
            </b-input-group>
        </div>
        <b-form-text>Leave the dropdown blank to run the import for all theatres.</b-form-text>
    </div>
</template>

<script>
import {BFormSelect, BButton, BInputGroup, BFormText} from "bootstrap-vue";
import Heading from "./Heading";
import SpinnerBars from "./SpinnerBars";
import axios from "axios";
import dayjs from "dayjs";

export default {
    props: ['timestamp', 'title', 'job'],
    components: {
        Heading,
        BFormSelect,
        BButton,
        BInputGroup,
        BFormText,
        SpinnerBars,
    },
    data() {
        let storeGetters = this.$store.getters;

        return {
            running: false,
            time: dayjs.unix(this.timestamp),
            theatre: null,
            theatres: storeGetters.theatres.map(t => ({value: t.code, text: t.name})),
        }
    },
    methods: {
        onTheatreChange(code) {
            this.theatre = code
        },
        runImport() {
            this.running = true;

            return axios
                .post(`/settings/run`, {
                    job: this.job,
                    theatre: this.theatre,
                })
                .then(response => {
                    this.running = false;
                    this.time = dayjs.unix(response.data.data)
                })
                .catch(error => {
                    this.running = false;
                    this.$toast.error(error.message);
                })
        }
    }
}
</script>
