import Vue from "vue";

export default {
    methods: {
        tableLoading(state) {
            this.fetching = state;

            if (state) {
                Vue.prototype.$bar.start();
            } else {
                Vue.prototype.$bar.finish();
            }

            return this;
        }
    }
}
