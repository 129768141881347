import axios from "axios";

const
    types = {
        ADMISSIONS: 'admissions',
    },
    state = {
        [types.ADMISSIONS]: {},
    },
    mutations = {
        [types.ADMISSIONS](state, {source_title, admissions}) {
            state[types.ADMISSIONS][source_title] = admissions;
        },
    },
    getters = {
        getAdmissions: state => source_title => state[types.ADMISSIONS][source_title],
    },
    actions = {
        /**
         * Fetch all admissions for a given source title.
         * @param {function} commit
         * @param {object} getters
         * @param {string} source_title
         * @param {boolean|number} mvxRequired
         * @return {Promise<array>}
         */
        fetchAdmissionsForTitle({commit, getters}, {source_title, mvxRequired = 1}) {
            const cached = getters.getAdmissions(source_title);

            if (cached) {
                return Promise.resolve(cached);
            }

            return axios
                .get(`/admissions/movie?source_title=${source_title}&mvx_required=${+mvxRequired}`)
                .then(response => {
                    commit(types.ADMISSIONS, {source_title: source_title, admissions: response.data.data});
                    return response.data.data
                })
        },
    };

export default {
    state,
    mutations,
    getters,
    actions
}
