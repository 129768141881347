var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stargate-login", style: { backgroundColor: _vm.bgColor } },
    [
      _c("div", { staticClass: "stargate-login-box text-center" }, [
        _c("header", [
          _c("h1", { staticClass: "sr-only" }, [_vm._v(_vm._s(_vm.appName))]),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src:
                "https://images.brightfish.be/apps/showtimes/showtimes-logo-blue.svg",
              alt: _vm.appName + " Login"
            }
          }),
          _vm._v(" "),
          _vm.title
            ? _c("p", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.title))])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mt-4 mb-3" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-primary btn-lg",
              attrs: { href: "/", rel: "noopener nofollow" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.redirect.apply(null, arguments)
                }
              }
            },
            [_vm._v("Login through Stargate")]
          )
        ]),
        _vm._v(" "),
        _c("footer", { staticClass: "text-muted" }, [
          _c("small", [
            _vm._v(
              "\n                " +
                _vm._s(new Date().getFullYear()) +
                " ©\n                "
            ),
            _c(
              "a",
              {
                staticClass: "text-muted",
                attrs: {
                  href: "https://brightfish.be",
                  rel: "noopener",
                  target: "_blank"
                }
              },
              [_vm._v("Brightfish")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }