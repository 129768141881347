<template>
    <div>
        <view-nav title="Session management" @nav="w => this.week = w">
            <template v-slot:content="{ emit }">
                <weeks-nav @nav="emit"/>
            </template>
        </view-nav>

        <div class="view-content container-fluid">
            <div class="row">
                <div class="col-2">
                    <ul class="box nav">
                        <li class="nav-item w-100">
                            <router-link to="/sessions" exact-path class="nav-link">
                                <icon name="settings" class="red"></icon> Sessions by lineup
                            </router-link>
                        </li>
                    </ul>
                    <div class="box">
                        <div class="box-content">
                            <heading v-if="childRoute === 'SessionsByLineup'" upper="Help" border>
                                <template v-slot:lower><small>{{ helpText }}</small></template>
                            </heading>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <router-view :week="week"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Heading from "../partials/Heading";
import ViewNav from "../partials/ViewNav";
import WeeksNav from "../partials/WeeksNav";

export default {
    components: {
        Heading,
        ViewNav,
        WeeksNav,
    },
    data() {
        return {
            week: null,
            childRoute: this.$route.name,
            helpText: '',
        }
    },
    watch: {
        $route(to) {
            this.childRoute = to.name;
        },
        week(week) {
            this.setHelpText(week);
        }
    },
    methods: {
        setHelpText(week) {
            this.helpText = `Aggregated sessions from ${week.start.format('DD MMM YYYY')} to ${week.end.format('DD MMM YYYY')}.`;
        }
    }
}
</script>
