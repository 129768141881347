import axios from "axios";

const
    types = {
        MAPPINGS: 'mappings',
        MAPPINGS_TOTAL_ROWS: 'mappings_total_rows',
    },
    state = {
        [types.MAPPINGS]: [],
    },
    mutations = {
        [types.MAPPINGS](state, arr) {
            state[types.MAPPINGS] = arr;
        },
    },
    getters = {
        getMappings: state => state[types.MAPPINGS],
    },
    actions = {
        /**
         * Update a mapping on the server and in the store.
         * @param {function} commit
         * @param {object} getters
         * @param {number} id ID of existing mapping
         * @param {object} data Movix data
         * @return {Promise<array>}
         */
        storeMapping({commit, getters}, {id, data}) {
            return axios
                .put(`/unknown-movies/${id}`, {
                    movix_id: data.id,
                })
                /** Remove the mapping from the store */
                .then(() => {
                    const mappings = getters.getMappings,
                        idx = mappings.findIndex(mapping => mapping.id = id);

                    mappings.splice(idx, 1);

                    return mappings;
                });
        },
        /**
         * Update a mapping on the server and in the store.
         * @param {function} commit
         * @param {object} getters
         * @param {number} id ID of existing mapping
         * @param {object} data Movix data
         * @return {Promise<array>}
         */
        ignoreMapping({commit, getters}, {id}) {
            return axios
                .delete(`/unknown-movies/${id}`)
                /** Remove the mapping from the store */
                .then(() => {
                    const mappings = getters.getMappings,
                        idx = mappings.findIndex(mapping => mapping.id = id);

                    mappings.splice(idx, 1);

                    return mappings;
                });
        },
    };

export default {
    state,
    mutations,
    getters,
    actions
}
