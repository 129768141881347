<template>
    <div class="box-content">
        <heading border>
            <template v-slot:upper>
                <span>{{ title }}</span>
                <spinner-bars v-if="running"/>
                <span v-else>{{ time.format('DD/MM/YYYY HH:mm:ss') }}</span>
            </template>
        </heading>
        <div>
            <b-button size="sm" @click="runImport" variant="primary">Import</b-button>
        </div>
    </div>
</template>

<script>
import {BButton} from "bootstrap-vue";
import Heading from "./Heading";
import SpinnerBars from "./SpinnerBars";
import axios from "axios";
import dayjs from "dayjs";

export default {
    props: ['timestamp', 'job', 'title'],
    components: {
        Heading,
        BButton,
        SpinnerBars,
    },
    data() {
        return {
            running: false,
            time: dayjs.unix(this.timestamp),
        }
    },
    methods: {
        runImport() {
            this.running = true;

            return axios
                .post(`/settings/run`, {
                    job: this.job,
                })
                .then(response => {
                    this.running = false;
                    this.time = dayjs.unix(response.data.data)
                })
                .catch(error => {
                    this.running = false;
                    this.$toast.error(error.message);
                })
        }
    }
}
</script>
