import axios from "axios";

const
    types = {
        STATS: 'stats',
    },
    state = {
        [types.STATS]: {},
    },
    mutations = {
        [types.STATS](state, {week, stats}) {
            state[types.STATS][week] = stats;
        }
    },
    getters = {
        getStats: state => week => state[types.STATS][week || 'current'],
    },
    actions = {
        fetchStats({commit}, week) {
            if (state[types.STATS][week]) {
                return Promise.resolve(state[types.STATS][week]);
            }

            week = week || 'current';

            return axios
                .get(`/stats/${week}`)
                .then(response => {
                    commit(types.STATS, {week: week, stats: response.data.data});
                    return response.data.data;
                })
        }
    };

export default {
    state,
    mutations,
    getters,
    actions
}
