var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box-content" },
    [
      _c("heading", {
        attrs: { border: "" },
        scopedSlots: _vm._u([
          {
            key: "upper",
            fn: function() {
              return [
                _c("span", [_vm._v(_vm._s(_vm.title))]),
                _vm._v(" "),
                _vm.running
                  ? _c("spinner-bars")
                  : _c("span", [
                      _vm._v(_vm._s(_vm.time.format("DD/MM/YYYY HH:mm:ss")))
                    ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "b-button",
            {
              attrs: { size: "sm", variant: "primary" },
              on: { click: _vm.runImport }
            },
            [_vm._v("Import")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }