<template>
    <div class="stargate-login" :style="{ backgroundColor: bgColor }">
        <div class="stargate-login-box text-center">
            <header>
                <h1 class="sr-only">{{ appName }}</h1>
                <img src="https://images.brightfish.be/apps/showtimes/showtimes-logo-blue.svg"
                     :alt="appName + ' Login'">
                <p v-if="title" class="mt-2">{{ title }}</p>
            </header>
            <p class="mt-4 mb-3">
                <a @click.prevent="redirect"
                   class="btn btn-primary btn-lg"
                   href="/"
                   rel="noopener nofollow">Login through Stargate</a>
            </p>
            <footer class="text-muted">
                <small>
                    {{ (new Date()).getFullYear() }} &copy;
                    <a href="https://brightfish.be" rel="noopener" target="_blank" class="text-muted">Brightfish</a>
                </small>
            </footer>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['appName', 'title', 'bgColor'],
        methods: {
            redirect() {
                window.location.href = this.$stargate.createAuthorisationUrl()
            }
        }
    }
</script>

<style scoped>
    .stargate-login {
        position: fixed; top: 0; left: 0; z-index: 9999;
        width: 100%; height: 100%;
    }
    .stargate-login-box {
        position: absolute; top: 46%; left: 50%;
        transform: translate(-50%, -45%);
        width: 90%;
        max-width: 280px;
    }
</style>
