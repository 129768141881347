export default Object.assign({}, window.appGlobals, {

    apiBase: '/api/v1',

    defaultLocale: 'en',

    locales: {
        en: 'English',
        fr: 'Français',
        nl: 'Nederlands'
    },

    errorLevels: [
        'emergency',
        'alert',
        'critical',
        'error',
        'warning',
        'notice',
        'info',
        'debug',
    ],

    movieMapping: {
        yearDiffThreshold: 2
    },

    // Max future date to create new movies with the MovieCreateForm plugin
    createMovieMaxFutureYears: 1,
})
