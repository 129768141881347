<template>
    <login app-name="Showtimes"
           :title="$config.tag_line"
           bg-color="#1c1e2f"></login>
</template>

<script>
    import Login from "./../../../vendor/brightfish/stargate-client/resources/js/components/Login";

    export default {
        components: {
            Login
        }
    }
</script>
