var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "app-header" } }, [
    _c("nav", [
      _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c("ul", { staticClass: "nav" }, [
          _c(
            "li",
            { staticClass: "nav-item" },
            [
              _vm.logged && _vm.user.can("manage_settings")
                ? _c(
                    "router-link",
                    {
                      staticClass: "nav-link border-hover",
                      attrs: { to: "/dashboard", "exact-path": "" }
                    },
                    [_vm._v("Dashboard\n                    ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item" },
            [
              _vm.logged && _vm.user.can("manage_settings")
                ? _c(
                    "router-link",
                    {
                      staticClass: "nav-link border-hover",
                      attrs: { to: "/sessions", "exact-path": "" }
                    },
                    [_vm._v("Sessions\n                    ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item" },
            [
              _vm.logged && _vm.user.can("manage_settings")
                ? _c(
                    "router-link",
                    {
                      staticClass: "nav-link border-hover",
                      attrs: { to: "/movie-mapping" }
                    },
                    [_vm._v("Movix mapping\n                    ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item" },
            [
              _vm.logged && _vm.user.can("manage_settings")
                ? _c(
                    "router-link",
                    {
                      staticClass: "nav-link border-hover",
                      attrs: { to: "/playlists" }
                    },
                    [_vm._v("Playlists\n                    ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item" },
            [
              _vm.logged && _vm.user.can("manage_settings")
                ? _c(
                    "router-link",
                    {
                      staticClass: "nav-link border-hover",
                      attrs: { to: "/admin" }
                    },
                    [_vm._v("Admin\n                    ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item ml-4 overflow-hidden" },
            [_c("toast", { staticClass: "nav-link" })],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _vm.logged
          ? _c("div", { staticClass: "user-menu" }, [
              _c(
                "div",
                { staticClass: "user-nav" },
                [
                  _c("div", { staticClass: "user-img" }, [
                    _vm.user.photo
                      ? _c("img", {
                          staticClass: "w-100",
                          attrs: { src: _vm.user.photo, alt: _vm.user.username }
                        })
                      : _c("span", [
                          _vm._v(_vm._s(_vm.user.username.charAt(0)))
                        ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { staticClass: "border-hover", attrs: { to: "/profile" } },
                    [_vm._v(_vm._s(_vm.$t("menu.Profile")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "border-hover",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.logout.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("menu.Logout")))]
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "/" } }, [
      _c("img", {
        staticClass: "mr-4",
        attrs: {
          height: "38",
          src:
            "https://images.brightfish.be/apps/showtimes/showtimes-logo-blue.svg",
          alt: "Showtimes home"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }