import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./lang";
import dateTimeFormats from "./date";

Vue.use(VueI18n);

const i18n = new VueI18n({
    messages: messages,
    dateTimeFormats: dateTimeFormats
});

export default (locale = null) => {
    if (locale) i18n.locale = locale;
    return i18n;
};
