var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "view-nav" }, [
      _c("div", { staticClass: "container-fluid p-0" }, [
        _c("div", { staticClass: "row align-items-center" }, [
          _c("div", { staticClass: "col-2" }, [
            _vm._v(_vm._s(_vm.$t("profile.YourProfile")))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-10" })
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "view-content container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-7" }, [
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "box-content" }, [_c("locale-changer")], 1)
          ])
        ]),
        _vm._v(" "),
        _vm._m(1)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2" }, [
      _c("div", { staticClass: "box" }, [
        _vm._v("\n                     \n                ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("div", { staticClass: "box" }, [
        _vm._v("\n                     \n                ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }