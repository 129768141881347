import axios from "axios";

const
    types = {
        SESSIONS: 'sessions',
    },
    state = {
        [types.SESSIONS]: {},
    },
    mutations = {
        [types.SESSIONS](state, {source_title, sessions}) {
            state[types.SESSIONS][source_title] = sessions;
        },
    },
    getters = {
        getSessions: state => source_title => state[types.SESSIONS][source_title],
    },
    actions = {
        /**
         * Fetch all sessions for a given source title.
         * @param {function} commit
         * @param {object} getters
         * @param {string} source_title
         * @param {boolean|number} mvxRequired
         * @return {Promise<array>}
         */
        fetchSessionsForTitle({commit, getters}, {source_title, mvxRequired = 1}) {
            const cached = getters.getSessions(source_title);

            if (cached) {
                return Promise.resolve(cached);
            }

            return axios
                .get(`/sessions/movie?source_title=${source_title}&mvx_required=${+mvxRequired}`)
                .then(response => {
                    commit(types.SESSIONS, {source_title: source_title, sessions: response.data.data});
                    return response.data.data
                })
        },
        fetchSessionsForTheatre({commit, getters}, params) {
            return axios
                .get(`/sessions/${params.theatre}?weeks=${params.week}`)
                .then(response => {
                    return response.data.data.sessions
                })
        }
    };

export default {
    state,
    mutations,
    getters,
    actions
}
